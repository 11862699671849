import { Box, Tooltip as ChakraTooltip, Flex } from '@chakra-ui/react';
import { ActionIcon, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider, modals } from '@mantine/modals';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { HiMiniInformationCircle } from 'react-icons/hi2';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TZipName } from 'src/Pages/Upload/types';
import { deleteReport } from 'src/Redux/UploadReports/slice';
import { IUploadReportsData } from 'src/Redux/UploadReports/state';
import { useZipFromLinks, useZipFromLinksWithFolder } from 'src/hooks';
import { RootState, useAppDispatch } from '../../../../Redux/Store';
import { getRandomUUID } from '../../../../utils/helpers';
import {
  FileInfoModal,
  HandleEditRowManually,
  renderTopToolbar,
} from './components/TableComponents';

export type uploadTable = {
  financial: string;
  financialYearStatement: string;
  // financialYear: string | null;
  // financialStatement: string;
  id: number;
  files?: {
    comment: string | null;
    link: string | null;
    linkId?: number | null;
    urlKey: string | null;
  }[];
  updatedInfo: string;
};

export type FormObj = {
  applicationId: string | null;
  applicantId: string | null;
  financial: string | null;
  financialYear: string | null;
  financialStatement: string | null;
  financialFileWithComment: { file: File; comment?: string | null }[] | null;
};

export interface UploadFilesTableProps {
  formState: FormObj;
  isTableLoading: boolean;
  zipName: TZipName;
}

export const getDocumentTableData = ({
  applicantReportsData,
}: {
  applicantReportsData: IUploadReportsData[];
}) => {
  const data = applicantReportsData?.map((report) => ({
    financial: report.reportInfo.type,
    financialYearStatement: report.reportInfo.timeFrame
      ? `${report.reportInfo.timeFrame + '-'}${report.reportInfo.reportName}`
      : `${report.reportInfo.reportName}`,
    financialYear: report.reportInfo.timeFrame,
    id: report.id,
    files: report.reportLinks,
    updatedInfo: report.updatedInfo,
  }));
  return data;
};

const UploadFilesTable = ({
  formState,
  isTableLoading,
  zipName,
}: UploadFilesTableProps) => {
  // hookes
  const [openedFileInfo, { open: openFileInfo, close: closeFileInfo }] =
    useDisclosure(false);
  const dispatch = useAppDispatch();
  const { createZipFromLinks, loading, error } = useZipFromLinks();
  const {
    createZipFromFolderAndLinks,
    error: folderZipError,
    loading: folderZipLoading,
  } = useZipFromLinksWithFolder();
  const location = useLocation();
  const { showBox } = location.state || {};
  // states
  const [selectedEditRow, setSelectedEditRow] = useState<{
    row: MRT_Row<uploadTable> | null;
    table: MRT_TableInstance<uploadTable> | null;
  }>({
    row: null,
    table: null,
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [tableData, setTableData] = useState<uploadTable[]>([]);
  const [isEditSaving, setIsEditSaving] = useState<boolean>(false);
  const [fileInfoIndex, setFileInfoIndex] = useState<number>(0);
  const [currentUpdateRowId, setCurrentUpdateRowId] = useState<number | null>(
    null
  );
  const userData = useSelector(
    (state: RootState) => state.brokerageDashboard.user
  );
  const uploadReports = useSelector(
    (state: RootState) => state.uploadReportsSlice
  );
  const currentApplicationId = userData?.data?.applicationId;
  // let Applicantbrokerage = userData && userData.data;

  const uploadData = useSelector(
    (state: RootState) => state.documentSlice?.data
  );

  // useEffect(() => {
  //   if (currentApplicationId) {
  //     const reportData = {
  //       applicationId: 30,
  //       type: 'Financial Statement',
  //       timeFrame: null,
  //       reportName: 'Cashflow',
  //     };
  //     dispatch(getReportList(reportData));
  //   }
  // }, [currentApplicationId, formState]);

  useEffect(() => {
    const { status, type, applicantReportsData } = uploadReports;
    switch (status) {
      case 'loading': {
        break;
      }
      case 'succeed': {
        if (type === 'GET_APPLICANT_REPORTS' && applicantReportsData) {
          const data = getDocumentTableData({
            applicantReportsData,
          });
          data && setTableData(data);
        }
        if (type === 'UPDATE_REPORT') {
          close();
        }
        break;
      }
      case 'failed': {
        break;
      }

      default:
        break;
    }
    return () => {};
  }, [uploadReports.status]);

  const columns = useMemo<MRT_ColumnDef<uploadTable>[]>(
    () => [
      {
        accessorKey: 'financial',
        header: 'Type',
        size: 20,
      },
      {
        accessorKey: 'financialYearStatement',
        header: 'Description',
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: 'updatedInfo',
        header: 'Time',
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: 'files',
        header: 'Files',
        Cell: ({ cell, row }) => {
          return (
            <Flex gap={5} alignItems={'center'}>
              <ChakraTooltip
                fontSize="md"
                label="File Download"
                placement="auto"
              >
                <Box>
                  <CgFileDocument
                    size={20}
                    onClick={() => {
                      createZipFromLinks({
                        links: (cell?.getValue() as any[])?.map(
                          (item: any) => item?.link
                        ),
                        zipName: `${zipName.applicantName} - ${zipName.applicationName || ''}`,
                      });
                    }}
                  />
                </Box>
              </ChakraTooltip>
              <ChakraTooltip fontSize="md" label="Files Info" placement="auto">
                <div>
                  <HiMiniInformationCircle
                    size={22}
                    cursor={'pointer'}
                    onClick={() => {
                      openFileInfo();
                      setFileInfoIndex(row.index);
                    }}
                  />
                </div>
              </ChakraTooltip>
            </Flex>
          );
        },
      },
    ],
    []
  );

  // DELETE REPORT HANDLER
  const handleDeleteReport = (row: MRT_Row<uploadTable>) =>
    modals.openConfirmModal({
      title: `DELETE`,
      children: (
        <Text>
          Are you sure you want to delete {row.original.id}? This action cannot
          be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        if (!isNaN(row?.original?.id)) {
          dispatch(
            deleteReport({
              reportID: row?.original?.id,
            })
          );
        }
      },
    });

  const isInitialTableLoading =
    uploadReports.status === 'loading' &&
    uploadReports.type === 'GET_APPLICANT_REPORTS';

  const handleExportData = () => {
    const zipFolderName = `${zipName.applicantName} - ${zipName.applicationName}`;

    const links = tableData.map((data) => ({ links: data.files }));

    createZipFromFolderAndLinks({
      folderWisedata: tableData.map((data) => {
        // let subFolder = data.;
        // if (data.financial === 'Financial Statement') {
        //   folderName = `${data.financial} ${data.financialYearStatement || ''}`;
        // }
        return {
          mainFolder: data.financial!,
          subFolder: data.financialYearStatement!,
          links: data.files?.map((file) => file.link!)!,
        };
      }),
      zipName: zipFolderName,
    });
  };

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    // layoutMode: 'grid',
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row, index) => getRandomUUID(),
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions'] },
    },
    // mantineTableProps: {
    //   sx: {
    //     tableLayout: 'fixed',
    //   },
    // },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: isInitialTableLoading ? 'hidden' : 'auto',
      },
      className: 'upload-reports-table',
    },
    mantineTableHeadCellProps: {
      sx: {
        '& .mantine-TableHeadCell-Content': {
          justifyContent: 'space-between',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 1, //make actions column wider
        maxSize: 1,
        minSize: 1,
        mantineTableHeadCellProps: {
          sx: {
            // maxWidth: '100px',
            zIndex: 999,
          },
        },
      },
      'mrt-row-expand': {
        size: 2, //make actions column wider
        maxSize: 2,
        minSize: 2,
        mantineTableHeadCellProps: {
          sx: {
            zIndex: 999,
          },
        },
      },
    },
    enableTopToolbar: true,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit" position="right">
          <ActionIcon
            disabled={showBox}
            onClick={() => {
              setSelectedEditRow({
                row,
                table,
              });
              open();
            }}
            // onClick={() => table.setEditingRow(row)}
            size={'sm'}
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete" position="right">
          <ActionIcon
            disabled={showBox}
            color="red"
            onClick={() => handleDeleteReport(row)}
            size={'sm'}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbar: ({ table }) =>
      renderTopToolbar({
        table,
        isTableLoading,
        handleExportData,
        isExportDisabled: !!tableData.length,
      }),
    state: {
      showProgressBars: isTableLoading,
      isLoading: isInitialTableLoading,
    },
  });

  return (
    <Box py={1}>
      <Flex direction={'column'} gap={12} alignItems={'center'}>
        <Box maxW={'1550px'} w={'100%'} py={5}>
          <ModalsProvider>
            <MantineReactTable table={table} key={'upload'} />
          </ModalsProvider>
          {selectedEditRow.table && selectedEditRow.row && (
            <HandleEditRowManually
              table={selectedEditRow.table}
              row={selectedEditRow.row}
              applicantReportsData={uploadReports?.applicantReportsData}
              isEditSaving={isEditSaving}
              modalOpen={opened}
              onClose={close}
              status={uploadReports.status}
            />
          )}
          {openedFileInfo && (
            <FileInfoModal
              isOpen={openedFileInfo}
              onClose={closeFileInfo}
              filesInfo={
                uploadReports?.applicantReportsData?.[fileInfoIndex]
                  ?.reportLinks!
              }
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default UploadFilesTable;
