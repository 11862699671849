import {
  Box,
  Button,
  Container,
  Input,
  Select,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import AutoComplete from 'react-google-autocomplete';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CheckQboStatus } from '../../../Redux/Applicant/Qbo/Qbo';
import { OngoingApplicant } from '../../../Redux/Broker/Dashboard/BrokerageDashboard';
import { fetchDashboardDetails } from '../../../Redux/Notification/Notification';
import { RootState } from '../../../Redux/Store';
import {
  addFocusEventListenerOnInput,
  deepObjectComparison,
  getRandomUUID,
  preventAlphabetsTyping,
  removeFields,
  removeFocusEventListenerOnInput,
  trimStringValues,
} from '../../../utils/helpers';
import { PersonalFinancialInfoSchema } from '../../../ValidationSchema/Pages/Index';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs';

const PersonalFinancialInfo = forwardRef<any, any>(
  (
    {
      isSubmited,
      setIsSubmited,
      ownerDetails,
      ownersCount,
      handleSetChildFormData,
      setSubmitForm,
      submitForm,
      isReturnToSender,
      setIsReturnToSender,
      ownersFinancialInfo,
      historyOwnersFinancialInfo,
      index,
      setAssignToId,
      assignToId,
      isOwnerForm,
      resetSubmitValues,
      formData,
    },
    ref
  ) => {
    const Toast = useToast();
    const dispatch = useDispatch();

    const [assignTo, setAssignTo] = useState<'owner' | 'broker' | ''>('');

    const dobDatePickerRef = useRef<DatePickerInput>(null);
    const inPositionSincePickerRef = useRef<DatePickerInput>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { showBox, ownerData, showHistory, applicationStatus } =
      location.state || {};

    const userData: any = useSelector(
      (state: RootState) => state.createApplicationFormSlice.user
    );
    const HistoryData: any = useSelector(
      (state: RootState) => state.history.user
    );

    const brokerageDashboard: any = useSelector(
      (state: RootState) => state.brokerageDashboard
    );

    let userGetidData = userData && userData.data;
    let assetsRealEstatedata = userGetidData && userGetidData.assetsRealEstate;
    const [realEstatebox, setRealEstatebox] = useState(
      assetsRealEstatedata ? assetsRealEstatedata.length : 1
    );
    let brokerid = userGetidData && userGetidData.brokerId;
    let financialInfoId = userGetidData && userGetidData.financialInfoId;
    // let applicationId = userGetidData && userGetidData.applicationId;
    const data: any = useSelector(
      (state: RootState) => state.brokerageDashboard.user
    );
    const onGoingFormData = data?.data?.financialInfo;

    let financialInfoFormData = ownersFinancialInfo[index];
    // let ongoingapplicationId =
    //   data && data?.data?.accountingSystem?.applicationId;
    const applicationId = formData?.applicationId;

    let ongoingbrokerId = data && data?.data?.accountingSystem?.brokerId;
    let ongoingfinancialInfoId =
      data && data?.data?.financialInfo?.financialInfoId;

    const paramsData = useParams<{ id: string }>();
    const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
    //applicant fetch
    const applicantFetch = useSelector(
      (state: RootState) => state.notification.user
    );
    const applicantFetchData =
      applicantFetch && applicantFetch?.data?.applications;

    const qboState = useSelector((state: RootState) => state.qbo);

    const userType = localStorage.getItem('userType');
    const isApplicantUser = userType === 'applicant';
    const isBrokerUser = userType === 'broker';
    const isOwnerUser = userType === 'owner' || isOwnerForm;
    const autoCompleteRef: any = useRef(null);
    const [input, setInput] = useState<any>([{ ref: React.createRef() }]);
    const assetsPriceOptions = [
      'Cash/GIC',
      'RRSP',
      'Stocks & Bonds',
      'Cash value of life Insurance',
      'Pensions',
    ];
    const liabilitiesPriceOptions = [
      'Personal loan/Lines of Credit Outstanding',
      'Additional owings / Outstanding amounts',
    ];
    const liabilitiesAddressOptions = [
      'Personal residence mortgage',
      'Other Real Estate Mortgage',
    ];

    useEffect(() => {
      // if (ongoingID) {
      //   fetchOngoingApplicant();
      // }
      /*!showHistory &&
        dispatch(fetchDashboardDetails({ applicationFilter: "" }) as any);*/
    }, [dispatch, ongoingID]);

    const fetchOngoingApplicant = async () => {
      if (!!localStorage.getItem('Token')) {
        let actionResult = await dispatch(
          OngoingApplicant({ id: ongoingID }) as any
        );
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };

    const fetchDashboardData = async () => {
      if (!!localStorage.getItem('Token')) {
        let actionResult = await dispatch(
          fetchDashboardDetails({ applicationFilter: '' }) as any
        );
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    const applicantObject = applicantFetchData?.find(
      (applicant: any) => applicant.applicationId === ongoingID
    );
    useImperativeHandle(ref, () => ({
      formik: formik,
    }));

    //   useEffect(() => {
    //     const fetchData = async () => {
    //         if (!!localStorage.getItem("Token")) {
    //             if (ongoingID && showHistory) {
    //                 let actionResult = await dispatch(getApplicantHistory(
    //                     { applicationId: ongoingID, modelName: "Financial Info" }) as any);;
    //                 if (actionResult?.payload?.status === 401) {
    //                     navigate("/login");
    //                 }
    //             }
    //         } else {
    //             navigate("/login");
    //         }
    //     }
    //     fetchData();
    // }, []);

    // useEffect(() => {
    //   if (ongoingID && showHistory) {
    //     dispatch(
    //       getApplicantHistory({
    //         applicationId: ongoingID,
    //         modelName: "Financial Info",
    //       }) as any
    //     );
    //   }
    // }, []);

    // useEffect(() => {
    //   if (HistoryData) {
    //     // financialInfoFormData = HistoryData?.data[historyIndex]?.details;
    //   }
    // }, [historyIndex, HistoryData]);

    useEffect(() => {
      if (
        financialInfoFormData
        // financialInfoFormData?.ownerInfo &&
        // financialInfoFormData?.assetsRealEstate &&
        // financialInfoFormData?.gic &&
        // financialInfoFormData?.assetsRrsp &&
        // financialInfoFormData?.businessInterests &&
        // financialInfoFormData?.liabilitiesRealEstate &&
        // financialInfoFormData?.personalLoans &&
        // financialInfoFormData?.additionalOutstanding &&
        // financialInfoFormData?.ownerInfo?.length > 0 &&
        // financialInfoFormData?.assetsRealEstate?.length > 0 &&
        // financialInfoFormData?.gic?.length > 0 &&
        // financialInfoFormData?.assetsRrsp?.length > 0 &&
        // financialInfoFormData?.businessInterests?.length > 0 &&
        // financialInfoFormData?.liabilitiesRealEstate?.length > 0 &&
        // financialInfoFormData?.personalLoans?.length > 0 &&
        // financialInfoFormData?.additionalOutstanding?.length > 0
      ) {
        const ownerInfo = financialInfoFormData?.ownerInfo
          ? {
              nameHoldco: financialInfoFormData?.ownerInfo?.nameHoldco || null,
              dateOfBirth: financialInfoFormData?.ownerInfo?.dateOfBirth
                ? financialInfoFormData?.ownerInfo?.dateOfBirth
                : null,
              position: financialInfoFormData?.ownerInfo?.position || null,

              inPositionSince: financialInfoFormData?.ownerInfo?.inPositionSince
                ? financialInfoFormData?.ownerInfo?.inPositionSince
                : null,
              ownershipPercentage:
                financialInfoFormData?.ownerInfo?.ownershipPercentage ?? null,
            }
          : {
              nameHoldco: null,
              dateOfBirth: null,
              position: null,
              inPositionSince: null,
              ownershipPercentage: null,
            };
        const assetsRealEstate =
          financialInfoFormData?.assetsRealEstate &&
          financialInfoFormData?.assetsRealEstate?.length
            ? financialInfoFormData?.assetsRealEstate?.map((data: any) => {
                return {
                  id: data.id || getRandomUUID(),
                  address: data.address || null,
                  value: data.value ?? null,
                };
              })
            : [{ id: getRandomUUID(), address: null, value: null }];
        let inputRef = assetsRealEstate.map((item: any, i: number) => {
          return {
            ref: {
              current: document.getElementById(`assetsRealEstate[${i}].id`),
              value: item?.address,
            },
          };
        });
        setInput(inputRef);
        const assetsPrice =
          financialInfoFormData?.assetsPrice &&
          financialInfoFormData?.assetsPrice?.length
            ? financialInfoFormData?.assetsPrice?.map((data: any) => {
                const dataValues: Record<string, any> = {
                  id: data.id || getRandomUUID(),
                  type: data.type || assetsPriceOptions[0],
                  value: data.value ?? null,
                };
                if (data.type === 'Others') {
                  dataValues['otherAssetType'] = data.otherAssetType || null;
                }
                return dataValues;
              })
            : [
                {
                  id: getRandomUUID(),
                  type: assetsPriceOptions[0],
                  value: null,
                },
              ];
        const gic =
          financialInfoFormData?.gic && financialInfoFormData?.gic?.length
            ? financialInfoFormData?.gic?.map((data: any) => ({
                id: data.id || getRandomUUID(),
                cash: data.cash || null,
                cashValue: data.cashValue || null,
              }))
            : [{ id: getRandomUUID(), cash: null, cashValue: null }];
        const assetsRrsp =
          financialInfoFormData?.assetsRrsp &&
          financialInfoFormData?.assetsRrsp?.length
            ? financialInfoFormData?.assetsRrsp?.map((data: any) => ({
                id: data.id || getRandomUUID(),
                stocksBonds: data.stocksBonds || null,
                bondsValue: data.bondsValue || null,
              }))
            : [{ id: getRandomUUID(), stocksBonds: null, bondsValue: null }];
        const businessInterests =
          financialInfoFormData?.businessInterests &&
          financialInfoFormData?.businessInterests?.length
            ? financialInfoFormData?.businessInterests?.map((data: any) => ({
                id: data.id || getRandomUUID(),
                legalName: data.legalName || null,
                value: data.value ?? null,
                ownershipPercentage: data.ownershipPercentage ?? null,
              }))
            : [
                {
                  id: getRandomUUID(),
                  legalName: null,
                  value: null,
                  ownershipPercentage: null,
                },
              ];
        const liabilitiesRealEstate =
          financialInfoFormData?.liabilitiesRealEstate &&
          financialInfoFormData?.liabilitiesRealEstate?.length
            ? financialInfoFormData?.liabilitiesRealEstate?.map(
                (data: any) => ({
                  id: data.id || getRandomUUID(),
                  // address: data.address || null,
                  mortgage: data.mortgage || null,
                  value: data.value ?? null,
                })
              )
            : [
                {
                  id: getRandomUUID(),
                  address: null,
                  mortgage: null,
                  value: null,
                },
              ];
        const liabilitiesPrice =
          financialInfoFormData?.liabilitiesPrice &&
          financialInfoFormData?.liabilitiesPrice?.length
            ? financialInfoFormData?.liabilitiesPrice?.map((data: any) => {
                const dataValues: Record<string, any> = {
                  id: data.id || getRandomUUID(),
                  type: data.type || liabilitiesPriceOptions[0],
                  value: data.value ?? null,
                };
                if (data.type === 'Others') {
                  dataValues['otherLiabilityType'] =
                    data.otherLiabilityType || null;
                }
                return dataValues;
              })
            : [
                {
                  id: getRandomUUID(),
                  type: liabilitiesPriceOptions[0],
                  value: null,
                },
              ];
        const liabilitiesAddress =
          financialInfoFormData?.liabilitiesAddress &&
          financialInfoFormData?.liabilitiesAddress?.length
            ? financialInfoFormData?.liabilitiesAddress?.map((data: any) => {
                return {
                  id: data.id ?? getRandomUUID(),
                  type: data.type ?? liabilitiesAddressOptions[0],
                  address: data.address ?? null,
                  value: data.value ?? null,
                };
              })
            : [
                {
                  id: getRandomUUID(),
                  type: liabilitiesAddressOptions[0],
                  address: null,
                  value: null,
                },
              ];
        const personalLoans =
          financialInfoFormData?.personalLoans &&
          financialInfoFormData?.personalLoans?.length
            ? financialInfoFormData?.personalLoans?.map((data: any) => ({
                id: data.id || getRandomUUID(),
                outstanding: data.outstanding || null,
                value: data.value ?? null,
              }))
            : [{ id: getRandomUUID(), outstanding: null, value: null }];
        const additionalOutstanding =
          financialInfoFormData?.additionalOutstanding &&
          financialInfoFormData?.additionalOutstanding?.length
            ? financialInfoFormData?.additionalOutstanding?.map(
                (data: any) => ({
                  id: data.id || getRandomUUID(),
                  amounts: data.amounts || null,
                  value: data.value ?? null,
                })
              )
            : [{ id: getRandomUUID(), amounts: null, value: null }];
        formik.setValues({
          applicationId: financialInfoFormData?.applicationId ?? applicationId,
          brokerId: financialInfoFormData?.brokerId || null,
          ownerInfo: ownerInfo,
          applicantId: ongoingID || null,
          assetsResidential: {
            address: financialInfoFormData?.assetsResidential?.address || null,
            value: financialInfoFormData?.assetsResidential?.value ?? null,
          },
          assetsRealEstate: assetsRealEstate,
          assetsPrice: assetsPrice,
          gic: gic,
          assetsRrsp: assetsRrsp,
          businessInterests: businessInterests,
          liabilitiesAddress: liabilitiesAddress,
          liabilitiesResidence: {
            mortgage:
              financialInfoFormData?.liabilitiesResidence?.mortgage || null,
            value: financialInfoFormData?.liabilitiesResidence?.value ?? null,
          },
          liabilitiesRealEstate: liabilitiesRealEstate,
          liabilitiesPrice: liabilitiesPrice,
          personalLoans: personalLoans,
          additionalOutstanding: additionalOutstanding,

          totalLiabilities: financialInfoFormData?.totalLiabilities || null,
          totalAssets: financialInfoFormData?.totalAssets || null,
          personalNetWorth: financialInfoFormData?.personalNetWorth || null,
          returnToSender: userType === 'broker' ? false : null,
          applicationName: financialInfoFormData?.applicationName || '',
        });
      } else if (
        Array.isArray(applicantFetchData) &&
        applicantObject?.financialInfo &&
        applicantObject?.financialInfo?.ownerInfo &&
        applicantObject?.financialInfo?.assetsRealEstate &&
        applicantObject?.financialInfo?.gic &&
        applicantObject?.financialInfo?.assetsRrsp &&
        applicantObject?.financialInfo?.businessInterests &&
        applicantObject?.financialInfo?.liabilitiesRealEstate &&
        applicantObject?.financialInfo?.personalLoans &&
        applicantObject?.financialInfo?.additionalOutstanding &&
        applicantObject?.financialInfo?.ownerInfo?.length > 0 &&
        applicantObject?.financialInfo?.assetsRealEstate?.length > 0 &&
        applicantObject?.financialInfo?.gic?.length > 0 &&
        applicantObject?.financialInfo?.assetsRrsp?.length > 0 &&
        applicantObject?.financialInfo?.businessInterests?.length > 0 &&
        applicantObject?.financialInfo?.liabilitiesRealEstate?.length > 0 &&
        applicantObject?.financialInfo?.personalLoans?.length > 0 &&
        applicantObject?.financialInfo?.additionalOutstanding?.length > 0
      ) {
        const ownerInfo = applicantObject?.financialInfo?.ownerInfo?.map(
          (data: any) => ({
            nameHoldco: data.nameHoldco || null,

            dateOfBirth: data.dateOfBirth ? data.dateOfBirth : null,
            position: data.position || null,

            inPositionSince: data.inPositionSince ? data.inPositionSince : null,
            ownershipPercentage: data.ownershipPercentage ?? null,
          })
        );
        const assetsRealEstate =
          applicantObject?.financialInfo?.assetsRealEstate?.map(
            (data: any) => ({
              address: data.address || null,
              value: data.value ?? null,
            })
          );
        let inputRef = assetsRealEstate.map((item: any, i: number) => {
          return {
            ref: {
              current: document.getElementById(`assetsRealEstate[${i}].id`),
              value: item?.address,
            },
          };
        });
        setInput(inputRef);
        const assetsPrice =
          applicantObject?.financialInfo?.assetsPrice &&
          applicantObject?.financialInfo?.assetsPrice?.length
            ? applicantObject?.financialInfo?.assetsPrice?.map((data: any) => {
                return {
                  id: data.id || getRandomUUID(),
                  type: data.type || null,
                  value: data.value ?? null,
                };
              })
            : [{ id: getRandomUUID(), type: null, value: null }];
        const gic = applicantObject?.financialInfo?.gic?.map((data: any) => ({
          cash: data.cash || null,
          cashValue: data.cashValue || null,
        }));
        const assetsRrsp = applicantObject?.financialInfo?.assetsRrsp?.map(
          (data: any) => ({
            stocksBonds: data.stocksBonds || null,
            bondsValue: data.bondsValue || null,
          })
        );
        const businessInterests =
          applicantObject?.financialInfo?.businessInterests?.map(
            (data: any) => ({
              legalName: data.legalName || null,
              value: data.value ?? null,
              ownershipPercentage: data.ownershipPercentage ?? null,
            })
          );
        const liabilitiesRealEstate =
          applicantObject?.financialInfo?.liabilitiesRealEstate?.map(
            (data: any) => ({
              // address: data.address || null,
              mortgage: data.mortgage || null,
              value: data.value ?? null,
            })
          );
        const liabilitiesPrice =
          applicantObject?.financialInfo?.liabilitiesPrice &&
          applicantObject?.financialInfo?.liabilitiesPrice?.length
            ? applicantObject?.financialInfo?.liabilitiesPrice?.map(
                (data: any) => {
                  return {
                    id: data.id || getRandomUUID(),
                    type: data.type || null,
                    value: data.value ?? null,
                  };
                }
              )
            : [{ id: getRandomUUID(), type: null, value: null }];
        const liabilitiesAddress =
          applicantObject?.financialInfo?.liabilitiesAddress &&
          applicantObject?.financialInfo?.liabilitiesAddress?.length
            ? applicantObject?.financialInfo?.liabilitiesAddress?.map(
                (data: any) => {
                  return {
                    id: data.id ?? getRandomUUID(),
                    type: data.type ?? liabilitiesAddressOptions[0],
                    address: data.address ?? null,
                    value: data.value ?? null,
                  };
                }
              )
            : [
                {
                  id: getRandomUUID(),
                  type: liabilitiesAddressOptions[0],
                  address: null,
                  value: null,
                },
              ];
        const personalLoans =
          applicantObject?.financialInfo?.personalLoans?.map((data: any) => ({
            outstanding: data.outstanding || null,
            value: data.value ?? null,
          }));
        const additionalOutstanding =
          applicantObject?.financialInfo?.additionalOutstanding?.map(
            (data: any) => ({
              amounts: data.amounts || null,
              value: data.value ?? null,
            })
          );
        formik.setValues({
          applicationId:
            applicantObject?.financialInfo?.applicationId ?? applicationId,
          brokerId: applicantObject?.financialInfo?.brokerId || null,
          ownerInfo: ownerInfo,
          applicantId: ongoingID || null,
          assetsResidential: {
            address:
              applicantObject?.financialInfo?.assetsResidential?.address ||
              null,
            value:
              applicantObject?.financialInfo?.assetsResidential?.value ?? null,
          },
          assetsRealEstate: assetsRealEstate,
          assetsPrice: assetsPrice,
          gic: gic,
          assetsRrsp: assetsRrsp,
          businessInterests: businessInterests,
          liabilitiesAddress: liabilitiesAddress,
          liabilitiesResidence: {
            mortgage:
              applicantObject?.financialInfo?.liabilitiesResidence?.mortgage ||
              null,
            value:
              applicantObject?.financialInfo?.liabilitiesResidence?.value ??
              null,
          },
          liabilitiesRealEstate: liabilitiesRealEstate,
          liabilitiesPrice: liabilitiesPrice,
          personalLoans: personalLoans,
          additionalOutstanding: additionalOutstanding,

          totalLiabilities:
            applicantObject?.financialInfo?.totalLiabilities || null,
          totalAssets: applicantObject?.financialInfo?.totalAssets || null,
          personalNetWorth:
            applicantObject?.financialInfo?.personalNetWorth || null,
          returnToSender: userType === 'broker' ? false : null,
          applicationName:
            applicantObject?.financialInfo?.applicationName || null,
        });
      }
    }, [userGetidData, financialInfoFormData, applicantObject, HistoryData]);

    interface FormValues {
      applicationId: number | null;
      brokerId: number | null;
      applicantId: number | null;

      ownerInfo: {
        nameHoldco: string | null;
        dateOfBirth: string | null;
        position: string | null;
        inPositionSince: string | null;
        ownershipPercentage: number | null;
      };
      assetsResidential: {
        address: string | null;
        value: number | null;
      };
      assetsRealEstate: [
        {
          id: string;
          address: string | null;
          value: number | null;
        },
      ];
      assetsPrice: [
        {
          id: string;
          type: string | null;
          otherAssetType?: string | null;
          value: number | null;
        },
      ];
      gic: [
        {
          id: string;
          cash: string | null;
          cashValue: number | null;
        },
      ];
      assetsRrsp: [
        {
          id: string;
          stocksBonds: string | null;
          bondsValue: number | null;
        },
      ];
      businessInterests: [
        {
          id: string;
          legalName: string | null;
          value: number | null;
          ownershipPercentage: number | null;
        },
      ];
      liabilitiesAddress: [
        {
          id: string;
          type: string | null;
          address: string | null;
          value: number | null;
        },
      ];
      liabilitiesResidence: {
        mortgage: string | null;
        value: number | null;
      };

      liabilitiesRealEstate: [
        {
          id: string;
          // address: string | null;
          mortgage: string | null;
          value: number | null;
        },
      ];
      liabilitiesPrice: [
        {
          id: string;
          type: string | null;
          otherLiabilityType?: string | null;
          value: number | null;
        },
      ];
      personalLoans: [
        {
          id: string;
          outstanding: string | null;
          value: number | null;
        },
      ];
      additionalOutstanding: [
        {
          id: string;
          amounts: string | null;
          value: number | null;
        },
      ];
      totalLiabilities: number | null;
      totalAssets: number | null;
      personalNetWorth: number | null;
      returnToSender: boolean | null;
      applicationName: string;
    }

    const initialValues: FormValues = useMemo(
      () => ({
        // applicationId: ongoingapplicationId,
        // brokerId: ongoingbrokerId,
        applicationId: applicationId,

        brokerId: brokerid ? brokerid : ongoingbrokerId,
        applicantId: ongoingID,

        ownerInfo: {
          nameHoldco:
            ownerDetails?.firstName || ownerDetails?.lastName
              ? `${ownerDetails?.firstName || ''} ${ownerDetails?.lastName || ''}`.trim()
              : null,
          dateOfBirth: ownerDetails?.dateOfBirth || null,
          position: ownerDetails?.position || null,
          inPositionSince: ownerDetails?.inPositionSince || null,
          ownershipPercentage: ownerDetails?.ownershipPercentage || null,
        },
        assetsResidential: {
          address: null,
          value: null,
        },
        assetsRealEstate: [
          {
            id: getRandomUUID(),
            address: null,
            value: null,
          },
        ],
        assetsPrice: [
          {
            id: getRandomUUID(),
            type: assetsPriceOptions[0],
            value: null,
          },
        ],
        gic: [
          {
            id: getRandomUUID(),
            cash: null,
            cashValue: null,
          },
        ],
        assetsRrsp: [
          {
            id: getRandomUUID(),
            stocksBonds: null,
            bondsValue: null,
          },
        ],
        businessInterests: [
          {
            id: getRandomUUID(),
            legalName: null,
            value: null,
            ownershipPercentage: null,
          },
        ],
        liabilitiesAddress: [
          {
            id: getRandomUUID(),
            type: liabilitiesAddressOptions[0],
            address: null,
            value: null,
          },
        ],
        liabilitiesResidence: {
          mortgage: null,
          value: null,
        },

        liabilitiesRealEstate: [
          {
            id: getRandomUUID(),
            // address: null,
            mortgage: null,
            value: null,
          },
        ],
        liabilitiesPrice: [
          {
            id: getRandomUUID(),
            type: liabilitiesPriceOptions[0],
            value: null,
          },
        ],
        personalLoans: [
          {
            id: getRandomUUID(),
            outstanding: null,
            value: null,
          },
        ],
        additionalOutstanding: [
          {
            id: getRandomUUID(),
            amounts: null,
            value: null,
          },
        ],
        totalLiabilities: null,
        totalAssets: null,
        personalNetWorth: null,
        returnToSender: userType === 'broker' ? false : null,
        applicationName: '',
      }),
      []
    );

    const OnHandelReturnToSender = () => {
      setIsReturnToSender(() => true);
      setSubmitForm(true);
    };

    const handleAssignTo = (to: 'owner' | 'broker', id: number) => {
      setAssignTo(() => to);
      setAssignToId(() => id);
      if (to === 'broker') setIsReturnToSender(() => true);
      setSubmitForm(true);
    };

    const fixIndexMisMatch = (formData: any[], apiData: any[]) => {
      if (formData?.length > apiData?.length) {
        return apiData;
      } else {
        const formDataIds = formData?.map((item) => item?.id) || [];
        const newArr = apiData?.filter((item) =>
          formDataIds.includes(item?.id)
        );
        return newArr;
      }
    };

    // const handleUpdateByOwner = () => {
    //   setAssignTo(() => "");
    //   setIsReturnToSender(() => true);
    //   setSubmitForm(true);
    // }

    // const fetchId = financialInfoId ? financialInfoId : ongoingfinancialInfoId;
    const fetchId = true;

    const formik = useFormik({
      initialValues,
      validationSchema: PersonalFinancialInfoSchema,
      onSubmit: async (values: FormValues) => {
        // setSubmitForm(false);
        // setIsSubmited({ ...isSubmited, "Personal Financial Info": true });
        let res = '';
        // handleSetChildFormData(ownerDetails?.ownerId, values, isReturnToSender, "UPDATE");
        // return;

        try {
          // let updatedLiabilitiesAddress = values.liabilitiesAddress.map((item:any,i:number) => {
          //   return { ...item,id:item.id ?? getRandomUUID(),type: item.type ?? "" ,address: item.type === "Personal residence mortgage" ? values.assetsResidential.address : values.assetsRealEstate[0]?.address ,value:item.type === "Personal residence mortgage" ? values.assetsResidential.value : values.assetsRealEstate[0]?.value }
          // })

          if (assignTo === 'owner' || assignToId) {
            // const { applicantId, applicationId, applicationName, brokerId, returnToSender } = values;
            try {
              let editedValues: any = {};
              const filteredApiData = {
                ...financialInfoFormData,
                assetsPrice: fixIndexMisMatch(
                  values?.assetsPrice,
                  financialInfoFormData?.assetsPrice
                ),
                liabilitiesPrice: fixIndexMisMatch(
                  values?.liabilitiesPrice,
                  financialInfoFormData?.liabilitiesPrice
                ),
              };
              let updatedValues: any = deepObjectComparison(
                values,
                filteredApiData
              );
              // const keys: (keyof FormValues)[] = Object.keys(
              //   values
              // ) as (keyof FormValues)[];

              // for (const key of keys) {
              //   if (!financialInfoFormData[key]) {
              //     (updatedValues as any)[key] = values[key];
              //   }
              //   else if (financialInfoFormData[key] && !deepObjectComparison(values[key], financialInfoFormData[key])) {
              //     (updatedValues as any)[key] = values[key];
              //   }
              // }

              editedValues = {
                // ...editedValues,
                ...values,
                ownerId:
                  (isOwnerUser
                    ? financialInfoFormData?.ownerId
                    : ownerDetails?.ownerId) || null,
                assignToOwner: assignToId
                  ? !!(
                      assignTo === 'owner' &&
                      assignToId === ownerDetails?.ownerId
                    )
                  : false,
              };
              updatedValues = {
                ...updatedValues,
                ownerId: ownerDetails?.ownerId || null,
              };

              const fieldsToRemove = [
                'applicantId',
                'applicationId',
                'applicationName',
                'brokerId',
                'returnToSender',
                'gic',
                'assetsRrsp',
                'personalLoans',
                'additionalOutstanding',
                'liabilitiesAddress',
              ];
              const filterValues = trimStringValues(
                removeFields(editedValues, fieldsToRemove)
              );
              const updatedFilterValues = trimStringValues(
                removeFields(updatedValues, fieldsToRemove)
              );

              handleSetChildFormData({
                key: isOwnerUser
                  ? financialInfoFormData?.ownerId
                  : ownerDetails?.ownerId,
                formData: filterValues,
                updatedData: updatedFilterValues,
                applicationId:
                  financialInfoFormData?.applicationId ?? applicationId,
                isReturnToSender: true,
                flag: isOwnerUser ? 'OWNER_ACTION' : 'UPDATE',
              });
              setAssignTo('');
            } catch (error: any) {
              console.log('formik update error', error?.message);
            }
            return;
          }
          if (isOwnerUser) {
            // This condition runs if the owner clicks on Return to Broker button / update button
            try {
              let editedValues: any = {};
              const filteredApiData = {
                ...financialInfoFormData,
                assetsPrice: fixIndexMisMatch(
                  values?.assetsPrice,
                  financialInfoFormData?.assetsPrice
                ),
                liabilitiesPrice: fixIndexMisMatch(
                  values?.liabilitiesPrice,
                  financialInfoFormData?.liabilitiesPrice
                ),
              };
              let updatedValues: any = deepObjectComparison(
                values,
                filteredApiData
              );
              // const keys: (keyof FormValues)[] = Object.keys(
              //   values
              // ) as (keyof FormValues)[];

              // for (const key of keys) {
              //   if (!financialInfoFormData[key]) {
              //     (updatedValues as any)[key] = values[key];
              //   }
              //   else if (financialInfoFormData[key] && !deepObjectComparison(values[key], financialInfoFormData[key])) {
              //     (updatedValues as any)[key] = values[key];
              //   }
              // }

              editedValues = {
                // ...editedValues,
                ...values,
                ownerId: ownerDetails?.ownerId || null,
                assignToOwner: false,
              };
              updatedValues = {
                ...updatedValues,
                ownerId: ownerDetails?.ownerId || null,
              };

              const fieldsToRemove = [
                'applicantId',
                'applicationId',
                'applicationName',
                'brokerId',
                'returnToSender',
                'gic',
                'assetsRrsp',
                'personalLoans',
                'additionalOutstanding',
                'liabilitiesAddress',
              ];
              const filterValues = trimStringValues(
                removeFields(editedValues, fieldsToRemove)
              );
              const updatedFilterValues = trimStringValues(
                removeFields(updatedValues, fieldsToRemove)
              );

              handleSetChildFormData({
                key: ownerDetails?.ownerId,
                formData: filterValues,
                updatedData: updatedFilterValues,
                applicationId:
                  financialInfoFormData?.applicationId ?? applicationId,
                isReturnToSender: false,
                flag: 'OWNER_ACTION',
              });

              // API CALL
              /*const actionResult = await dispatch(
                PFinfoUpdate({
                  updatedData: values,
                  applicationId: ongoingapplicationId,
                }) as any
              );
              const response = actionResult.payload;
              res = response.msg;
              const resStatus = response.status;
              if (resStatus === 200) {
                Toast({
                  title: response.msg,
                  status: "success",
                  isClosable: true,
                  duration: 3000,
                  position: "top-right",
                });
                dispatch(OngoingApplicant({ id: ongoingID }) as any);
              } else {
                Toast({
                  title: res,
                  status: "error",
                  isClosable: true,
                  duration: 3000,
                  position: "top-right",
                });
              }*/
            } catch (error: any) {
              console.log('formik update error', error?.message);
            }
            return;
          } else {
            if (fetchId && !isReturnToSender) {
              try {
                let editedValues: any = {};
                const filteredApiData = {
                  ...financialInfoFormData,
                  assetsPrice: fixIndexMisMatch(
                    values?.assetsPrice,
                    financialInfoFormData?.assetsPrice
                  ),
                  liabilitiesPrice: fixIndexMisMatch(
                    values?.liabilitiesPrice,
                    financialInfoFormData?.liabilitiesPrice
                  ),
                };
                let updatedValues: any = deepObjectComparison(
                  values,
                  filteredApiData
                );
                // return;

                // for (const key of keys) {
                //   if (!financialInfoFormData?.[key]) {
                //     (updatedValues as any)[key] = values[key];
                //   }
                //   else if (financialInfoFormData[key] && !deepObjectComparison(values[key], financialInfoFormData[key])) {
                //     (updatedValues as any)[key] = values[key];
                //   }
                // }

                editedValues = {
                  // ...editedValues,
                  ...values,
                  ownerId: ownerDetails?.ownerId || null,
                };
                updatedValues = {
                  ...updatedValues,
                  ownerId: ownerDetails?.ownerId || null,
                };

                const fieldsToRemove = [
                  'applicantId',
                  'applicationId',
                  'applicationName',
                  'brokerId',
                  'returnToSender',
                  'gic',
                  'assetsRrsp',
                  'personalLoans',
                  'additionalOutstanding',
                  'liabilitiesAddress',
                ];
                const filterValues = trimStringValues(
                  removeFields(editedValues, fieldsToRemove)
                );
                const updatedFilterValues = trimStringValues(
                  removeFields(updatedValues, fieldsToRemove)
                );

                handleSetChildFormData({
                  key: ownerDetails?.ownerId,
                  formData: filterValues,
                  updatedData: updatedFilterValues,
                  applicationId:
                    financialInfoFormData?.applicationId ?? applicationId,
                  isReturnToSender: undefined,
                  flag: 'UPDATE',
                });
              } catch (error: any) {
                console.log('formik update error', error?.message);
              }

              /*const actionResult = await dispatch(
                PFinfoUpdate({
                  updatedData: values,
                  applicationId: ongoingapplicationId,
                }) as any
              );
              const response = actionResult.payload;
              res = response.msg;
              const resStatus = response.status;

              if (resStatus === 200) {
                Toast({
                  title: response.msg,
                  status: "success",
                  isClosable: true,
                  duration: 3000,
                  position: "top-right",
                });
                dispatch(OngoingApplicant({ id: ongoingID }) as any);
              } else {
                Toast({
                  title: res,
                  status: "error",
                  isClosable: true,
                  duration: 3000,
                  position: "top-right",
                });
              }*/
            } else if (isReturnToSender) {
              let editedValues: any = {};
              const filteredApiData = {
                ...financialInfoFormData,
                assetsPrice: fixIndexMisMatch(
                  values?.assetsPrice,
                  financialInfoFormData?.assetsPrice
                ),
                liabilitiesPrice: fixIndexMisMatch(
                  values?.liabilitiesPrice,
                  financialInfoFormData?.liabilitiesPrice
                ),
              };
              let updatedValues: any = deepObjectComparison(
                values,
                filteredApiData
              );
              // let updatedValues: any = {};
              // const keys: (keyof FormValues)[] = Object.keys(
              //   values
              // ) as (keyof FormValues)[];

              // for (const key of keys) {
              //   if (!financialInfoFormData[key]) {
              //     (updatedValues as any)[key] = values[key];
              //   }
              //   else if (financialInfoFormData[key] && !deepObjectComparison(values[key], financialInfoFormData[key])) {
              //     (updatedValues as any)[key] = values[key];
              //   }
              // }

              editedValues = {
                ...values,
                ownerId: ownerDetails?.ownerId || null,
              };
              updatedValues = {
                ...updatedValues,
                ownerId: ownerDetails?.ownerId || null,
              };

              const fieldsToRemove = [
                'applicantId',
                'applicationId',
                'applicationName',
                'brokerId',
                'returnToSender',
                'gic',
                'assetsRrsp',
                'personalLoans',
                'additionalOutstanding',
                'liabilitiesAddress',
              ];
              const filterValues = trimStringValues(
                removeFields(editedValues, fieldsToRemove)
              );
              const updatedFilterValues = trimStringValues(
                removeFields(updatedValues, fieldsToRemove)
              );

              handleSetChildFormData({
                key: ownerDetails?.ownerId,
                formData: filterValues,
                updatedData: updatedFilterValues,
                applicationId:
                  financialInfoFormData?.applicationId ?? applicationId,
                isReturnToSender: isReturnToSender,
                flag: 'UPDATE',
              });

              /*const actionResult = await dispatch(
                PFinfoUpdate({
                  updatedData: editedValues,
                  applicationId: ongoingapplicationId,
                }) as any
              );
              const response = actionResult.payload;*/
              // navigate("/");
              // setIsReturnToSender(false);
            } else {
              handleSetChildFormData({
                key: ownerDetails?.ownerId,
                formData: values,
                applicationId:
                  financialInfoFormData?.applicationId ?? applicationId,
                isReturnToSender: undefined,
                flag: 'INSERT',
              });

              /*const actionResult = await dispatch(PFinfo(values) as any);
              const response = actionResult.payload;
              res = response.msg;
              const resStatus = response.status;

              if (resStatus === 200) {
                Toast({
                  title: response.msg,
                  status: "success",
                  isClosable: true,
                  duration: 3000,
                  position: "top-right",
                });
                dispatch(OngoingApplicant({ id: ongoingID }) as any);

                dispatch(fetchPFinfoData() as any);
              } else {
                Toast({
                  title: res,
                  status: "error",
                  isClosable: true,
                  duration: 3000,
                  position: "top-right",
                });
              }*/
            }
          }
        } catch (error: any) {
          if (res) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      },
    });

    // useImperativeHandle(ref, () => ({
    //   formik: formik,
    // }));

    const handleAddBox = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const liabilitiesRealEstate = newValues.liabilitiesRealEstate || [];
        const assetsRealEstate = newValues?.assetsRealEstate || [];
        const id = crypto.randomUUID();

        liabilitiesRealEstate?.push({
          // address: null,
          mortgage: null,
          value: null,
        });

        setInput((prev: any) => {
          return [...prev, { ref: React.createRef() }];
        });
        assetsRealEstate?.push({ id: id, address: null, value: null });

        return {
          ...newValues,
          liabilitiesRealEstate,
          assetsRealEstate,
        };
      });
    };

    const handleRemoveBox = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const liabilitiesRealEstate = newValues.liabilitiesRealEstate || [];
        const assetsRealEstate = newValues?.assetsRealEstate || [];

        if (liabilitiesRealEstate?.length > 1 && assetsRealEstate.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          liabilitiesRealEstate?.splice(removeIndex, 1);

          assetsRealEstate?.splice(removeIndex, 1);

          input.splice(removeIndex, 1);
          for (let i = 0; i < input.length; i++) {
            input[i].ref.current = document.getElementById(
              `assetsRealEstate[${i}].id`
            );
            input[i].ref.current.value = assetsRealEstate[i]?.address;
          }

          return {
            ...newValues,
            liabilitiesRealEstate,
            assetsRealEstate,
          };
        }

        return newValues;
      });
    };

    const handleAddBox1 = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const assetsRealEstate = newValues?.assetsRealEstate || [];
        const id = crypto.randomUUID();
        setInput((prev: any) => {
          return [...prev, { ref: React.createRef() }];
        });
        assetsRealEstate?.push({ id: id, address: null, value: null });

        return {
          ...newValues,
          assetsRealEstate,
        };
      });
    };

    const handleRemoveBox1 = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const assetsRealEstate = newValues?.assetsRealEstate || [];

        if (assetsRealEstate?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          assetsRealEstate?.splice(removeIndex, 1);

          input.splice(removeIndex, 1);
          for (let i = 0; i < input.length; i++) {
            input[i].ref.current = document.getElementById(
              `assetsRealEstate[${i}].id`
            );
            input[i].ref.current.value = assetsRealEstate[i]?.address;
          }
          // setInput(input);

          return {
            ...newValues,
            assetsRealEstate,
          };
        }

        return newValues;
      });
    };

    const handleAddBox2 = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const gic = newValues.gic || [];

        gic?.push({ cash: null, cashValue: null });

        return {
          ...newValues,
          gic,
        };
      });
    };
    const handleAddBoxAssetsPrice = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const assetsPrice = newValues.assetsPrice || [];
        const nextOption = getFirstNotSelectedOption(
          assetsPriceOptions,
          assetsPrice
        );

        assetsPrice?.push({
          id: getRandomUUID(),
          type: nextOption,
          value: null,
        });

        return {
          ...newValues,
          assetsPrice,
        };
      });
    };
    const handleRemoveBoxAssetsPrice = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const assetsPrice = newValues.assetsPrice || [];

        if (assetsPrice?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          assetsPrice?.splice(removeIndex, 1);
          return {
            ...newValues,
            assetsPrice,
          };
        }

        return newValues;
      });
    };
    const handleAddBoxLiabilitiesAddress = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const liabilitiesAddress = newValues.liabilitiesAddress || [];

        let liabilitiesPrevEle = liabilitiesAddress[0]?.type;
        let liabilitiesAddType =
          liabilitiesPrevEle === 'Personal residence mortgage'
            ? liabilitiesAddressOptions[1]
            : liabilitiesAddressOptions[0];
        liabilitiesAddress?.push({
          id: getRandomUUID(),
          type: liabilitiesAddType,
          address: null,
          value: null,
        });

        return {
          ...newValues,
          liabilitiesAddress,
        };
      });
    };
    const handleRemoveBoxLiabilitiesAddress = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const liabilitiesAddress = newValues.liabilitiesAddress || [];

        if (liabilitiesAddress?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          liabilitiesAddress?.splice(removeIndex, 1);
          return {
            ...newValues,
            liabilitiesAddress,
          };
        }

        return newValues;
      });
    };
    const handleAddBoxLiabilitiesPrice = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const liabilitiesPrice = newValues.liabilitiesPrice || [];
        const nextOption = getFirstNotSelectedOption(
          liabilitiesPriceOptions,
          liabilitiesPrice
        );
        liabilitiesPrice?.push({
          id: getRandomUUID(),
          type: nextOption,
          value: null,
        });

        return {
          ...newValues,
          liabilitiesPrice,
        };
      });
    };
    const handleRemoveBoxLiabilitiesPrice = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const liabilitiesPrice = newValues.liabilitiesPrice || [];

        if (liabilitiesPrice?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          liabilitiesPrice?.splice(removeIndex, 1);
          return {
            ...newValues,
            liabilitiesPrice,
          };
        }

        return newValues;
      });
    };

    const handleRemoveBox2 = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const gic = newValues.gic || [];

        if (gic?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          gic?.splice(removeIndex, 1);
          return {
            ...newValues,
            gic,
          };
        }

        return newValues;
      });
    };

    const handleAddBox3 = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const personalLoans = newValues.personalLoans || [];

        personalLoans?.push({ outstanding: null, value: null });

        return {
          ...newValues,
          personalLoans,
        };
      });
    };

    const handleRemoveBox3 = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const personalLoans = newValues.personalLoans || [];

        if (personalLoans?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          personalLoans?.splice(removeIndex, 1);
          return {
            ...newValues,
            personalLoans,
          };
        }

        return newValues;
      });
    };

    const handleAddBox4 = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const assetsRrsp = newValues.assetsRrsp || [];

        assetsRrsp?.push({ stocksBonds: null, bondsValue: null });

        return {
          ...newValues,
          assetsRrsp,
        };
      });
    };

    const handleRemoveBox4 = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const assetsRrsp = newValues.assetsRrsp || [];

        if (assetsRrsp?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          assetsRrsp?.splice(removeIndex, 1);
          return {
            ...newValues,
            assetsRrsp,
          };
        }

        return newValues;
      });
    };

    const handleAddBox5 = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const additionalOutstanding = newValues.additionalOutstanding || [];

        additionalOutstanding?.push({ amounts: null, value: null });

        return {
          ...newValues,
          additionalOutstanding,
        };
      });
    };

    const handleRemoveBox5 = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const additionalOutstanding = newValues.additionalOutstanding || [];

        if (additionalOutstanding?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          additionalOutstanding?.splice(removeIndex, 1);
          return {
            ...newValues,
            additionalOutstanding,
          };
        }

        return newValues;
      });
    };

    const handleAddBox6 = (index: number) => {
      setRealEstatebox((prevCount: any) => prevCount + 1);

      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        const businessInterests = newValues.businessInterests || [];

        businessInterests?.push({
          legalName: null,
          value: null,
          ownershipPercentage: null,
        });

        return {
          ...newValues,
          businessInterests,
        };
      });
    };

    const handleRemoveBox6 = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        const businessInterests = newValues.businessInterests || [];

        if (businessInterests?.length > 1) {
          setRealEstatebox((prevCount: number) => prevCount - 1);
          const removeIndex = index;
          businessInterests?.splice(removeIndex, 1);
          return {
            ...newValues,
            businessInterests,
          };
        }

        return newValues;
      });
    };

    function truncateText(text: string, maxLength: number) {
      const words = text.split(' ');
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(' ') + '...';
      }
      return text;
    }

    const getTotalAssetValue = (formikValues: any) => {
      const {
        assetsResidential,
        assetsRealEstate,
        assetsPrice,
        businessInterests,
      } = formikValues;
      if (
        !assetsResidential ||
        !assetsRealEstate ||
        !assetsPrice ||
        !businessInterests
      ) {
        return 0;
      }

      const businessPercentValue = businessInterests?.reduce(
        (acc: any, current: any) => {
          const percentage =
            current?.ownershipPercentage > 100
              ? 100
              : current?.ownershipPercentage || 0;
          return acc + current?.value * (percentage / 100);
        },
        0
      );

      const allAssets = [
        ...[assetsResidential],
        ...assetsRealEstate,
        ...assetsPrice,
      ];
      const sumTotal = allAssets?.reduce((acc, current) => {
        return (
          acc +
          (current?.value || current?.bondsValue || current?.cashValue || 0)
        );
      }, 0);

      return sumTotal + businessPercentValue || null;
    };
    const getTotalLiabilityValue = (formikValues: any) => {
      const { liabilitiesResidence, liabilitiesRealEstate, liabilitiesPrice } =
        formikValues;
      if (
        !liabilitiesResidence ||
        !liabilitiesRealEstate ||
        !liabilitiesPrice
      ) {
        return 0;
      }
      const allLiabilities = [
        ...[liabilitiesResidence],
        ...liabilitiesRealEstate,
        ...liabilitiesPrice,
      ];
      const sumTotal = allLiabilities.reduce((acc, current) => {
        return (
          acc +
          (current?.value || current?.bondsValue || current?.cashValue || 0)
        );
      }, 0);
      return sumTotal || null;
    };

    function getFirstNotSelectedOption(
      options: String[],
      selectedOptions: Record<string, any>[]
    ) {
      for (const option of options) {
        if (!selectedOptions.some((item) => item.type === option)) {
          return option;
        }
      }
      return 'Others';
    }

    const collectFormikData = (formikValues: any) => {
      let editedValues: any = {};
      const filteredApiData = {
        ...financialInfoFormData,
        assetsPrice: fixIndexMisMatch(
          formikValues?.assetsPrice,
          financialInfoFormData?.assetsPrice
        ),
        liabilitiesPrice: fixIndexMisMatch(
          formikValues?.liabilitiesPrice,
          financialInfoFormData?.liabilitiesPrice
        ),
      };
      let updatedValues: any = deepObjectComparison(
        formikValues,
        filteredApiData
      );
      // const keys: (keyof FormValues)[] = Object.keys(
      //   values
      // ) as (keyof FormValues)[];

      // for (const key of keys) {
      //   if (
      //     !deepObjectComparison(values[key], financialInfoFormData[key])
      //   ) {
      //     (editedValues as any)[key] = values[key];
      //   }
      // }

      editedValues = {
        // ...editedValues,
        ...formikValues,
        ownerId:
          (isOwnerUser
            ? financialInfoFormData?.ownerId
            : ownerDetails?.ownerId) || null,
        assignToOwner: assignToId
          ? !!(assignTo === 'owner' && assignToId === ownerDetails?.ownerId)
          : false,
      };
      updatedValues = {
        ...updatedValues,
        ownerId: ownerDetails?.ownerId || null,
      };

      const fieldsToRemove = [
        'applicantId',
        'applicationId',
        'applicationName',
        'brokerId',
        'returnToSender',
      ];
      const filterValues = trimStringValues(
        removeFields(editedValues, fieldsToRemove)
      );
      const updatedFilterValues = trimStringValues(
        removeFields(updatedValues, fieldsToRemove)
      );

      handleSetChildFormData({
        key: isOwnerUser
          ? financialInfoFormData?.ownerId
          : ownerDetails?.ownerId,
        formData: filterValues,
        updatedData: updatedFilterValues,
        applicationId: financialInfoFormData?.applicationId ?? applicationId,
        isReturnToSender: true,
        flag: isOwnerUser ? 'OWNER_ACTION' : 'UPDATE',
      });
    };

    // Check QBO Status
    useEffect(() => {
      const appId = applicationId;
      if (appId && !qboState?.escReason) {
        fetchCheckQboStatus(appId);
      }
    }, [applicationId]);

    const fetchCheckQboStatus = async (appId: string) => {
      if (!!localStorage.getItem('Token')) {
        let actionResult = await dispatch(CheckQboStatus(appId) as any);
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };

    // Submit Form
    useEffect(() => {
      if (assignTo === 'owner' && assignToId === ownerDetails?.ownerId) {
        if (submitForm || brokerageDashboard?.submitPersonalFinanceForm) {
          formik.submitForm();
        }
      } else if (
        assignTo === '' &&
        assignToId &&
        assignToId !== ownerDetails?.ownerId
      ) {
        if (submitForm || brokerageDashboard?.submitPersonalFinanceForm) {
          collectFormikData(formik.values);
        }
      } else {
        if (submitForm || brokerageDashboard?.submitPersonalFinanceForm) {
          formik.submitForm();
        }
      }

      if (
        Object.keys(formik.errors).length &&
        assignToId === ownerDetails?.ownerId
      ) {
        // Reset submit values from OwnerPersonalFinanceInfo.tsx
        if (resetSubmitValues) {
          resetSubmitValues();
        }
        setAssignTo('');
      }
    }, [submitForm, brokerageDashboard]);

    // Show History data
    // useEffect(() => {
    //   if (showHistory && historyOwnersFinancialInfo) {
    //     formik.setValues({
    //       ...formik.values,
    //       ...historyOwnersFinancialInfo,
    //     });
    //   }
    // }, [showHistory, historyOwnersFinancialInfo]);

    // Calculate totalAssets, totalLiabilities and personalNetWorth
    useEffect(() => {
      const totalAssets = getTotalAssetValue(formik.values);
      const totalLiabilities = getTotalLiabilityValue(formik.values);
      const personalNetWorth =
        parseFloat((totalAssets - totalLiabilities).toFixed(2)) || null;

      formik.setFieldValue(
        'liabilitiesResidence.mortgage',
        formik.values?.assetsResidential?.address
      );
      formik.values?.liabilitiesRealEstate?.forEach((item: any, index: any) => {
        formik.setFieldValue(
          `liabilitiesRealEstate.[${index}].mortgage`,
          formik.values?.assetsRealEstate[index]?.address ?? null
        );
      });

      formik.setFieldValue('totalAssets', totalAssets);
      formik.setFieldValue('totalLiabilities', totalLiabilities);
      formik.setFieldValue('personalNetWorth', personalNetWorth);
    }, [formik.values]);

    const handleClear = (key: string, index?: any) => {
      formik.setFieldValue(key, null);
      if (key === 'assetsResidential.address') {
        autoCompleteRef.current.value = '';
      } else {
        input[index].ref.current.value = '';
      }
    };

    return (
      <>
        {/* <Box display="flex" gap={4} w="min(88rem,95%)" margin={"auto"}> */}
        <Container
          maxW={'container.lg'}
          boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
          h="auto"
          p="20px"
          borderRadius="20px"
          gap={5}
          bg={'white'}
          mt={index === 0 && !isOwnerForm ? '0' : '20px'}
          // mt={"-236px"}
          // overflow="auto"
          // height="calc(100vh - 150px)"
          // sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              display={'flex'}
              flexDirection="column"
              gap={5}
              boxShadow="0px 0px 5px 0px rgba(0,0,0,0.3)"
              borderRadius="20px"
              p="20px 20px 30px 20px"
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box width={'100%'}>
                  <Text
                    textAlign="start"
                    fontSize={12}
                    color="red"
                    fontWeight="600"
                  >
                    {/* Pending Applicant Consent ! */}
                  </Text>
                </Box>
                <Box width={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="center"
                    fontSize={20}
                  >
                    Personal Financial (Owner {index + 1}){' '}
                  </Text>
                </Box>

                <Box width={'100%'}></Box>
              </Box>
              {/* <Box>
                  {!isOwnerUser &&
                    !qboState.loading &&
                    applicationStatus !== 'Drafts' && (
                      <AlertBox
                        variant={
                          qboState?.creditDataInfo?.[index]?.access
                            ? 'success'
                            : 'error'
                        }
                        message={qboState?.creditDataInfo?.[index]?.status}
                      />
                    )}
                </Box> */}
              <Box
                display={'grid'}
                gridTemplateColumns={'repeat(auto-fill, minmax(270px, 1fr))'}
                gap={5}
              >
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={'column'}
                >
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>
                      Name/Holdco{' '}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </li>
                  </Text>

                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="Name/Holdco"
                    borderRadius="5px"
                    onChange={formik.handleChange}
                    readOnly={showBox}
                    value={formik.values.ownerInfo?.nameHoldco ?? ''}
                    name="ownerInfo.nameHoldco"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    bgColor={
                      showHistory &&
                      historyOwnersFinancialInfo?.ownerInfo &&
                      'nameHoldco' in historyOwnersFinancialInfo?.ownerInfo &&
                      historyOwnersFinancialInfo?.ownerInfo.nameHoldco !==
                        null &&
                      '#114684'
                    }
                    color={
                      showHistory &&
                      historyOwnersFinancialInfo?.ownerInfo &&
                      'nameHoldco' in historyOwnersFinancialInfo?.ownerInfo &&
                      historyOwnersFinancialInfo?.ownerInfo.nameHoldco !==
                        null &&
                      'white'
                    }
                  />
                  {formik.touched.ownerInfo &&
                    formik.errors.ownerInfo &&
                    typeof formik.errors.ownerInfo !== 'string' &&
                    formik.errors.ownerInfo?.nameHoldco && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.ownerInfo?.nameHoldco}
                      </div>
                    )}
                </Box>
                <Box
                  flex={{ base: '1', md: '1' }}
                  h={'100%'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={'column'}
                >
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>
                      Date of birth{' '}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </li>
                  </Text>
                  <DatePickerInput
                    ref={dobDatePickerRef}
                    value={
                      formik.values.ownerInfo?.dateOfBirth !== null
                        ? formik.values.ownerInfo?.dateOfBirth
                        : undefined
                    }
                    onChange={(date: Date | null) => {
                      formik.setFieldValue('ownerInfo.dateOfBirth', date);
                    }}
                    locale="en"
                    placeholder="Date of birth"
                    className={`react-datepicker-component react-datepicker-input input gray-border ${
                      showHistory &&
                      historyOwnersFinancialInfo?.ownerInfo &&
                      'dateOfBirth' in historyOwnersFinancialInfo?.ownerInfo &&
                      historyOwnersFinancialInfo?.ownerInfo?.dateOfBirth !==
                        null
                        ? 'bg-blue'
                        : ''
                    }`}
                    maxDate={eighteenYearsAgo}
                    disabled={showBox}
                    showOnInputClick={true}
                    onShow={() =>
                      addFocusEventListenerOnInput(dobDatePickerRef)
                    }
                    onHide={() =>
                      removeFocusEventListenerOnInput(dobDatePickerRef)
                    }
                  />
                  {formik.touched.ownerInfo &&
                    formik.errors.ownerInfo &&
                    typeof formik.errors.ownerInfo !== 'string' &&
                    formik.errors.ownerInfo?.dateOfBirth && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.ownerInfo?.dateOfBirth}
                      </div>
                    )}
                </Box>
                <Box
                  flex={{ base: '1', md: '1' }}
                  h={'100%'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={'column'}
                >
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mt={1}
                  >
                    <li>
                      Position{' '}
                      {qboState?.qboCompanyName &&
                        `at ${qboState?.qboCompanyName}`}
                    </li>
                  </Text>
                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="text"
                    placeholder="Position"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={formik.handleChange}
                    readOnly={showBox}
                    value={formik.values.ownerInfo?.position ?? ''}
                    name="ownerInfo.position"
                    bgColor={
                      showHistory &&
                      historyOwnersFinancialInfo?.ownerInfo &&
                      'position' in historyOwnersFinancialInfo?.ownerInfo &&
                      historyOwnersFinancialInfo?.ownerInfo?.position !==
                        null &&
                      '#114684'
                    }
                    color={
                      showHistory &&
                      historyOwnersFinancialInfo?.ownerInfo &&
                      'position' in historyOwnersFinancialInfo?.ownerInfo &&
                      historyOwnersFinancialInfo?.ownerInfo?.position !==
                        null &&
                      'white'
                    }
                  />
                </Box>
                <Box
                  flex={{ base: '1', md: '1' }}
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={'column'}
                >
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>In position since</li>
                  </Text>
                  <DatePickerInput
                    ref={inPositionSincePickerRef}
                    value={
                      formik.values.ownerInfo?.inPositionSince !== null
                        ? formik.values.ownerInfo?.inPositionSince
                        : undefined
                    }
                    onChange={(date: Date | null) => {
                      formik.setFieldValue('ownerInfo.inPositionSince', date);
                    }}
                    locale="en"
                    placeholder="In position since"
                    minDate={formik.values.ownerInfo.dateOfBirth || undefined}
                    maxDate={new Date()}
                    className={`react-datepicker-component react-datepicker-input input gray-border ${
                      showHistory &&
                      historyOwnersFinancialInfo?.ownerInfo &&
                      'inPositionSince' in
                        historyOwnersFinancialInfo?.ownerInfo &&
                      historyOwnersFinancialInfo?.ownerInfo?.inPositionSince !==
                        null
                        ? 'bg-blue'
                        : ''
                    }`}
                    disabled={showBox}
                    showOnInputClick={true}
                    onShow={() =>
                      addFocusEventListenerOnInput(inPositionSincePickerRef)
                    }
                    onHide={() =>
                      removeFocusEventListenerOnInput(inPositionSincePickerRef)
                    }
                  />
                  {formik.errors.ownerInfo &&
                    typeof formik.errors.ownerInfo !== 'string' &&
                    formik.errors.ownerInfo?.inPositionSince && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.ownerInfo?.inPositionSince}
                      </div>
                    )}
                </Box>
                <Box
                  flex={{ base: '1', md: '1' }}
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={'column'}
                  position={'relative'}
                >
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    mb={1}
                  >
                    <li>
                      {' '}
                      % ownership{' '}
                      {qboState?.qboCompanyName &&
                        `of ${qboState?.qboCompanyName}`}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </li>
                  </Text>
                  <Input
                    fontSize={'13px'}
                    w="100%"
                    type="number"
                    placeholder="% ownership"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={formik.handleChange}
                    readOnly={showBox}
                    value={formik.values.ownerInfo?.ownershipPercentage ?? ''}
                    name="ownerInfo.ownershipPercentage"
                    bgColor={
                      showHistory &&
                      historyOwnersFinancialInfo?.ownerInfo &&
                      'ownershipPercentage' in
                        historyOwnersFinancialInfo?.ownerInfo &&
                      historyOwnersFinancialInfo?.ownerInfo
                        ?.ownershipPercentage !== null &&
                      '#114684'
                    }
                    color={
                      showHistory &&
                      historyOwnersFinancialInfo?.ownerInfo &&
                      'ownershipPercentage' in
                        historyOwnersFinancialInfo?.ownerInfo &&
                      historyOwnersFinancialInfo?.ownerInfo
                        ?.ownershipPercentage !== null &&
                      'white'
                    }
                    onKeyDown={preventAlphabetsTyping}
                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  />
                  {formik.errors.ownerInfo &&
                    typeof formik.errors.ownerInfo !== 'string' &&
                    formik.errors.ownerInfo?.ownershipPercentage && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                          position: 'absolute',
                          bottom: '-20px',
                        }}
                      >
                        {formik.errors.ownerInfo?.ownershipPercentage}
                      </div>
                    )}
                </Box>
              </Box>
            </Box>

            <Box mt={5}>
              <Box
                boxShadow="0px 0px 5px 0px rgba(0,0,0,0.3)"
                borderRadius="20px"
                p="15px"
                display={'flex'}
                flexDirection="column"
                gap={5}
              >
                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="center"
                    fontSize={15}
                  >
                    FINANCIAL INFORMATION (Supporting documents such as RRSP
                    statements, tax assessments, company financial statements,
                    bank statements etc. should accompany the information,
                    otherwise they will be discounted from the total assets){' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Text>
                </Box>
                <Box
                  display={'flex'}
                  gap={5}
                  flexWrap={'wrap'}
                  flexDirection={{
                    base: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'column',
                    xl: 'row',
                  }}
                >
                  <Box
                    flex={{ base: 1, md: 1, lg: 1 }}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={3}
                  >
                    <Text fontWeight="600" color="#114684" fontSize={15} mb={2}>
                      ASSETS
                    </Text>
                    <Box
                      display={'flex'}
                      gap={1}
                      flexWrap={'wrap'}
                      flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
                    >
                      <Box
                        flex={{ base: 1, md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                          mb={1}
                        >
                          <li>
                            <span style={{ fontWeight: 'bold' }}>
                              Personal residential address
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </span>
                          </li>
                        </Text>
                        <Tooltip
                          label={
                            formik.values.assetsResidential?.address
                              ? formik.values.assetsResidential?.address
                              : 'Personal residential address'
                          }
                          placement="top"
                          hasArrow
                        >
                          <Box display="flex" position={'relative'}>
                            <AutoComplete
                              apiKey={GOOGLE_MAPS_API_KEY}
                              defaultValue={
                                formik.values.assetsResidential?.address ?? ''
                              }
                              onPlaceSelected={(place) => {
                                if (place && place.formatted_address) {
                                  formik.setFieldValue(
                                    'assetsResidential.address',
                                    place.formatted_address
                                  );
                                } else {
                                  formik.setFieldValue(
                                    'assetsResidential.address',
                                    ''
                                  );
                                }
                              }}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className={`googlelocationcity ${
                                showBox ? 'readonly' : ''
                              }`}
                              ref={autoCompleteRef}
                              options={{
                                types: [],
                              }}
                              style={{
                                padding: '7px 16px',
                                outline: '#7f7f7f',
                                backgroundColor:
                                  showHistory &&
                                  historyOwnersFinancialInfo?.assetsResidential &&
                                  'address' in
                                    historyOwnersFinancialInfo?.assetsResidential &&
                                  historyOwnersFinancialInfo?.assetsResidential
                                    ?.address !== null &&
                                  '#114684',
                                color:
                                  showHistory &&
                                  historyOwnersFinancialInfo?.assetsResidential &&
                                  'address' in
                                    historyOwnersFinancialInfo?.assetsResidential &&
                                  historyOwnersFinancialInfo?.assetsResidential
                                    ?.address !== null &&
                                  'white',
                              }}
                            />
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              bg={'#f0f5f9'}
                              mr={2}
                              cursor={'pointer'}
                              _hover={{ color: '#114684' }}
                              position={'absolute'}
                              top={'50%'}
                              transform={'translateY(-50%)'}
                              right={0}
                            >
                              {formik.values?.assetsResidential?.address &&
                                !showHistory &&
                                !showBox && (
                                  <FaTimes
                                    onClick={() =>
                                      handleClear('assetsResidential.address')
                                    }
                                  />
                                )}
                            </Box>
                          </Box>
                        </Tooltip>
                        {formik.touched.assetsResidential?.address &&
                          formik.errors.assetsResidential?.address && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {formik.errors.assetsResidential?.address}
                            </div>
                          )}
                      </Box>
                      <Box
                        flex={{ base: 1, md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                          mb={1}
                        >
                          <li>
                            <span style={{ fontWeight: 'bold' }}>
                              $ Market Value{' '}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </span>
                          </li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="number"
                          placeholder="$ Value of Personal residential address"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          readOnly={showBox}
                          value={formik.values.assetsResidential?.value ?? ''}
                          name="assetsResidential.value"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          bgColor={
                            showHistory &&
                            historyOwnersFinancialInfo?.assetsResidential &&
                            'value' in
                              historyOwnersFinancialInfo?.assetsResidential &&
                            historyOwnersFinancialInfo?.assetsResidential
                              ?.value !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            historyOwnersFinancialInfo?.assetsResidential &&
                            'value' in
                              historyOwnersFinancialInfo?.assetsResidential &&
                            historyOwnersFinancialInfo?.assetsResidential
                              ?.value !== null &&
                            'white'
                          }
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        />
                        {formik.touched.assetsResidential?.value &&
                          formik.errors.assetsResidential?.value && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {formik.errors.assetsResidential?.value}
                            </div>
                          )}
                      </Box>
                    </Box>
                    <Box>
                      {formik.values?.assetsRealEstate &&
                        formik.values?.assetsRealEstate?.map(
                          (data: any, index: any) => (
                            <Box
                              key={index}
                              display={{ base: 'block', md: 'flex' }}
                              flex={{ base: 1 }}
                              gap={1}
                            >
                              <Box flex={{ base: '1', md: '1' }}>
                                <Text
                                  fontWeight="600"
                                  color="#114684"
                                  textAlign="start"
                                  fontSize={13}
                                  mb={1}
                                >
                                  <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {/* {truncateText(
                                                "Other real estate addresses",
                                                2
                                              )} */}
                                      Other real estate addresses
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </span>
                                  </li>
                                </Text>
                                <Tooltip
                                  label={
                                    formik.values.assetsRealEstate[index]
                                      ?.address
                                      ? formik.values.assetsRealEstate[index]
                                          ?.address
                                      : 'Other real estate addresses'
                                  }
                                  placement="top"
                                  hasArrow
                                >
                                  {/* <Input
                                            fontSize={"13px"}
                                            w="100%"
                                            type="Text"
                                            placeholder="Additional real estate addresses"
                                            borderRadius="5px"
                                            onChange={formik.handleChange}
                                            readOnly={showBox}
                                            value={
                                              formik.values.assetsRealEstate[
                                                index
                                              ]?.address || ""
                                            }
                                            name={`assetsRealEstate[${index}].address`}
                                            focusBorderColor="#cc6699"
                                            borderColor={"#cc6699"}
                                            _hover={{ borderColor: "#cc6699" }}
                                            bgColor={
                                              showHistory &&
                                              historyOwnersFinancialInfo
                                                ?.assetsRealEstate?.[index] &&
                                              "address" in
                                              historyOwnersFinancialInfo
                                                ?.assetsRealEstate?.[index] &&
                                              "#114684"
                                            }
                                            color={
                                              showHistory &&
                                              historyOwnersFinancialInfo
                                                ?.assetsRealEstate?.[index] &&
                                              "address" in
                                              historyOwnersFinancialInfo
                                                ?.assetsRealEstate?.[index] &&
                                              "white"
                                            }
                                          /> */}
                                  <Box display="flex" position={'relative'}>
                                    <AutoComplete
                                      apiKey={GOOGLE_MAPS_API_KEY}
                                      defaultValue={
                                        formik.values.assetsRealEstate[index]
                                          ?.address || ''
                                      }
                                      onPlaceSelected={(place) => {
                                        if (place && place.formatted_address) {
                                          formik.setFieldValue(
                                            `assetsRealEstate[${index}].address`,
                                            place.formatted_address
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `assetsRealEstate[${index}].address`,
                                            null
                                          );
                                        }
                                      }}
                                      // onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                      className={`googlelocationcity ${
                                        showBox ? 'readonly' : ''
                                      }`}
                                      id={`assetsRealEstate[${index}].id`}
                                      ref={input[index]?.ref}
                                      options={{
                                        types: [],
                                      }}
                                      style={{
                                        padding: '7px 16px',
                                        outline: '#7f7f7f',
                                        backgroundColor:
                                          showHistory &&
                                          historyOwnersFinancialInfo
                                            ?.assetsRealEstate?.[index] &&
                                          'address' in
                                            historyOwnersFinancialInfo
                                              ?.assetsRealEstate?.[index] &&
                                          historyOwnersFinancialInfo
                                            ?.assetsRealEstate?.[index]
                                            ?.address !== null &&
                                          '#114684',
                                        color:
                                          showHistory &&
                                          historyOwnersFinancialInfo
                                            ?.assetsRealEstate?.[index] &&
                                          'address' in
                                            historyOwnersFinancialInfo
                                              ?.assetsRealEstate?.[index] &&
                                          historyOwnersFinancialInfo
                                            ?.assetsRealEstate?.[index]
                                            ?.address !== null &&
                                          'white',
                                      }}
                                    />
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      bg={'#f0f5f9'}
                                      mr={2}
                                      cursor={'pointer'}
                                      _hover={{ color: '#114684' }}
                                      position={'absolute'}
                                      top={'50%'}
                                      transform={'translateY(-50%)'}
                                      right={0}
                                    >
                                      {formik.values.assetsRealEstate[index]
                                        .address &&
                                        !showHistory &&
                                        !showBox && (
                                          <FaTimes
                                            onClick={() =>
                                              handleClear(
                                                `assetsRealEstate[${index}].address`,
                                                index
                                              )
                                            }
                                          />
                                        )}
                                    </Box>
                                  </Box>
                                </Tooltip>
                                {formik.touched.assetsRealEstate &&
                                  formik.errors.assetsRealEstate &&
                                  typeof formik.errors.assetsRealEstate[
                                    index
                                  ] !== 'string' &&
                                  formik.errors.assetsRealEstate[index] && // Ensure that the value is not null or undefined
                                  (formik.errors.assetsRealEstate[index] as any)
                                    ?.address && (
                                    <div
                                      style={{
                                        color: 'red',
                                        fontSize: '13px',
                                        textAlign: 'start',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {
                                        (
                                          formik.errors.assetsRealEstate[
                                            index
                                          ] as any
                                        ).address
                                      }
                                    </div>
                                  )}
                              </Box>
                              <Box flex={{ base: '1', md: '1' }}>
                                <Text
                                  fontWeight="600"
                                  color="#114684"
                                  textAlign="start"
                                  fontSize={13}
                                  mb={1}
                                >
                                  <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                      $ Market value
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </span>
                                  </li>
                                </Text>
                                <Input
                                  fontSize={'13px'}
                                  w="100%"
                                  type="number"
                                  placeholder="Other real estate $ value"
                                  borderRadius="5px"
                                  value={
                                    formik.values.assetsRealEstate[index]
                                      ?.value ?? ''
                                  }
                                  onChange={formik.handleChange}
                                  readOnly={showBox}
                                  name={`assetsRealEstate[${index}].value`}
                                  focusBorderColor="#7f7f7f"
                                  borderColor={'#7f7f7f'}
                                  _hover={{ borderColor: '#7f7f7f' }}
                                  bgColor={
                                    showHistory &&
                                    historyOwnersFinancialInfo
                                      ?.assetsRealEstate?.[index] &&
                                    'value' in
                                      historyOwnersFinancialInfo
                                        ?.assetsRealEstate?.[index] &&
                                    historyOwnersFinancialInfo
                                      ?.assetsRealEstate?.[index]?.value !==
                                      null &&
                                    '#114684'
                                  }
                                  color={
                                    showHistory &&
                                    historyOwnersFinancialInfo
                                      ?.assetsRealEstate?.[index] &&
                                    'value' in
                                      historyOwnersFinancialInfo
                                        ?.assetsRealEstate?.[index] &&
                                    historyOwnersFinancialInfo
                                      ?.assetsRealEstate?.[index]?.value !==
                                      null &&
                                    'white'
                                  }
                                  onKeyDown={preventAlphabetsTyping}
                                  onWheel={(e) =>
                                    (e.target as HTMLInputElement).blur()
                                  }
                                />
                                {formik.touched.assetsRealEstate &&
                                  formik.errors.assetsRealEstate &&
                                  typeof formik.errors.assetsRealEstate[
                                    index
                                  ] !== 'string' &&
                                  formik.errors.assetsRealEstate[index] &&
                                  (formik.errors.assetsRealEstate[index] as any)
                                    ?.value && (
                                    <div
                                      style={{
                                        color: 'red',
                                        fontSize: '13px',
                                        textAlign: 'start',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {
                                        (
                                          formik.errors.assetsRealEstate[
                                            index
                                          ] as any
                                        ).value
                                      }
                                    </div>
                                  )}
                              </Box>
                              {/* {index === 0 ? (
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          fontSize="xl"
                                          marginTop="18px"
                                          onClick={() => handleAddBox1(index)}
                                          cursor={
                                            showBox ? "not-allowed" : "pointer"
                                          }
                                          pointerEvents={
                                            showBox ? "none" : "auto"
                                          }
                                        >
                                          <CiCirclePlus />
                                        </Box>
                                      ) : (
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          fontSize="xl"
                                          marginTop="18px"
                                          onClick={() => handleRemoveBox1(index)}
                                          cursor={
                                            showBox ? "not-allowed" : "pointer"
                                          }
                                          pointerEvents={
                                            showBox ? "none" : "auto"
                                          }
                                        >
                                          <CiCircleMinus />
                                        </Box>
                                      )} */}
                            </Box>
                          )
                        )}
                    </Box>
                    <Box>
                      {formik.values.assetsPrice &&
                        formik.values.assetsPrice?.map(
                          (data: any, index: number) => (
                            <Box
                              key={index}
                              display={{ base: 'block', md: 'flex' }}
                              flex={{ base: 1 }}
                              gap={1}
                            >
                              <Box
                                display={{ base: 'flex' }}
                                justifyContent={'space-between'}
                                gap={{ base: 3 }}
                                alignItems={'center'}
                                flex={{ base: '1', md: '1' }}
                              >
                                <Box flex={{ base: '1', md: '1' }}>
                                  <Text
                                    fontWeight="600"
                                    color="#114684"
                                    textAlign="start"
                                    fontSize={13}
                                    mb={1}
                                  >
                                    <li>
                                      <span style={{ fontWeight: 'bold' }}>
                                        Select Dropdown{' '}
                                        {/* <span style={{ color: "red" }}>*</span> */}
                                      </span>
                                    </li>
                                  </Text>
                                  <Select
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                      if (e.target.value !== 'Others') {
                                        formik.setValues((prevValues: any) => {
                                          let newValues = {
                                            ...prevValues,
                                          };
                                          let assetPrice =
                                            newValues?.assetsPrice || [];
                                          let updatedAssetPrice =
                                            assetPrice?.map(
                                              (item: any, i: number) => {
                                                if (index === i) {
                                                  if (
                                                    assetPrice
                                                      .map(
                                                        (item: any) => item.type
                                                      )
                                                      .includes(e.target.value)
                                                  ) {
                                                    let matchItem =
                                                      assetPrice?.filter(
                                                        (asset: any) =>
                                                          asset.type ===
                                                          e.target.value
                                                      )[0];
                                                    delete matchItem?.otherAssetType;
                                                    return {
                                                      ...matchItem,
                                                    };
                                                  } else {
                                                    delete item?.otherAssetType;
                                                    return {
                                                      ...item,
                                                      type: e.target.value,
                                                    };
                                                  }
                                                }
                                                if (
                                                  item.type === e.target.value
                                                ) {
                                                  return assetPrice[index];
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                          return {
                                            ...newValues,
                                            assetsPrice: updatedAssetPrice,
                                          };
                                        });
                                      } else {
                                        formik.setFieldValue(
                                          `assetsPrice[${index}].type`,
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={
                                      formik.values.assetsPrice[index].type ??
                                      ''
                                    }
                                    name={`assetsPrice[${index}].type`}
                                    fontSize={'13px'}
                                    w="100%"
                                    borderRadius="5px"
                                    focusBorderColor="#7f7f7f"
                                    borderColor={'#7f7f7f'}
                                    _hover={{ borderColor: '#7f7f7f' }}
                                    isDisabled={showBox}
                                    _disabled={{ opacity: 1 }}
                                    bgColor={
                                      showHistory &&
                                      historyOwnersFinancialInfo &&
                                      'assetsPrice' in
                                        historyOwnersFinancialInfo &&
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ] &&
                                      'type' in
                                        historyOwnersFinancialInfo
                                          ?.assetsPrice?.[index] &&
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ]?.type !== null &&
                                      '#114684'
                                    }
                                    color={
                                      showHistory &&
                                      historyOwnersFinancialInfo &&
                                      'assetsPrice' in
                                        historyOwnersFinancialInfo &&
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ] &&
                                      'type' in
                                        historyOwnersFinancialInfo
                                          ?.assetsPrice?.[index] &&
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ]?.type !== null &&
                                      'white'
                                    }
                                  >
                                    {assetsPriceOptions.map(
                                      (option: string, i: number) => {
                                        const typesPresentArray =
                                          formik.values.assetsPrice.filter(
                                            (item: any, assetdIndex: number) =>
                                              assetdIndex < index
                                          );
                                        const typesPresent =
                                          typesPresentArray.map(
                                            (item) => item.type
                                          );

                                        if (!typesPresent.includes(option)) {
                                          return (
                                            <option key={i} value={option}>
                                              {option}
                                            </option>
                                          );
                                        }
                                        // <option key={i} value={option} disabled={(option !== 'Others' && formik.values.assetsPrice.some(item => item.type === option))}>{option}</option>
                                      }
                                    )}
                                    <option
                                      key={assetsPriceOptions?.length + 1}
                                      value={'Others'}
                                    >
                                      Others
                                    </option>
                                  </Select>

                                  {formik.touched.assetsPrice &&
                                    formik.errors.assetsPrice &&
                                    typeof formik.errors.assetsPrice?.[
                                      index
                                    ] !== 'string' &&
                                    formik.errors.assetsPrice[index] && // Ensure that the value is not null or undefined
                                    (formik.errors.assetsPrice[index] as any)
                                      ?.type && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '13px',
                                          textAlign: 'start',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {
                                          (
                                            formik.errors.assetsPrice[
                                              index
                                            ] as any
                                          ).type
                                        }
                                      </div>
                                    )}
                                </Box>
                                {index === 0 ? (
                                  <Box
                                    display={{ base: 'flex', md: 'none' }}
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop="18px"
                                    onClick={() =>
                                      handleAddBoxAssetsPrice(index)
                                    }
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCirclePlus />
                                  </Box>
                                ) : (
                                  <Box
                                    display={{ base: 'flex', md: 'none' }}
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop="18px"
                                    onClick={() =>
                                      handleRemoveBoxAssetsPrice(index)
                                    }
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCircleMinus />
                                  </Box>
                                )}
                              </Box>
                              {formik.values?.assetsPrice?.[index]?.type ===
                                'Others' && (
                                <Box flex={{ base: '1', md: '1' }}>
                                  <Text
                                    fontWeight="600"
                                    color="#114684"
                                    textAlign="start"
                                    fontSize={13}
                                    mb={1}
                                  >
                                    <li>
                                      <span style={{ fontWeight: 'bold' }}>
                                        Type{' '}
                                        {/* <span style={{ color: "red" }}>*</span> */}
                                      </span>
                                    </li>
                                  </Text>
                                  <Input
                                    fontSize={'13px'}
                                    w="100%"
                                    type="text"
                                    placeholder="Asset Type"
                                    borderRadius="5px"
                                    onChange={formik.handleChange}
                                    readOnly={showBox}
                                    value={
                                      formik.values.assetsPrice[index]
                                        ?.otherAssetType || ''
                                    }
                                    name={`assetsPrice[${index}].otherAssetType`}
                                    focusBorderColor="#7f7f7f"
                                    borderColor={'#7f7f7f'}
                                    _hover={{ borderColor: '#7f7f7f' }}
                                    bgColor={
                                      showHistory &&
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ] &&
                                      'otherAssetType' in
                                        historyOwnersFinancialInfo
                                          ?.assetsPrice?.[index] &&
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ]?.otherAssetType !== null &&
                                      // historyOwnersFinancialInfo?.assetsPrice?.[index]?.id === formik.values.assetsPrice[index]?.id &&
                                      '#114684'
                                    }
                                    color={
                                      showHistory &&
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ] &&
                                      'otherAssetType' in
                                        historyOwnersFinancialInfo
                                          ?.assetsPrice?.[index] &&
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ]?.otherAssetType !== null &&
                                      // historyOwnersFinancialInfo?.assetsPrice?.[index]?.id === formik.values.assetsPrice[index]?.id &&
                                      'white'
                                    }
                                  />
                                  {formik.touched.assetsPrice &&
                                    formik.errors.assetsPrice &&
                                    typeof formik.errors.assetsPrice[index] !==
                                      'string' &&
                                    formik.errors.assetsPrice[index] && // Ensure that the value is not null or undefined
                                    (formik.errors.assetsPrice[index] as any)
                                      ?.otherAssetType && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '13px',
                                          textAlign: 'start',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {
                                          (
                                            formik.errors.assetsPrice[
                                              index
                                            ] as any
                                          ).otherAssetType
                                        }
                                      </div>
                                    )}
                                </Box>
                              )}
                              <Box flex={{ base: '1', md: '1' }}>
                                <Text
                                  fontWeight="600"
                                  color="#114684"
                                  textAlign="start"
                                  fontSize={13}
                                  mb={1}
                                >
                                  <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                      $ Value{' '}
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </span>
                                  </li>
                                </Text>
                                <Input
                                  fontSize={'13px'}
                                  w="100%"
                                  type="number"
                                  placeholder="$ Value of Asset Price"
                                  borderRadius="5px"
                                  onChange={formik.handleChange}
                                  readOnly={showBox}
                                  value={
                                    formik.values.assetsPrice[index]?.value ??
                                    ''
                                  }
                                  name={`assetsPrice[${index}].value`}
                                  focusBorderColor="#7f7f7f"
                                  borderColor={'#7f7f7f'}
                                  _hover={{ borderColor: '#7f7f7f' }}
                                  bgColor={
                                    showHistory &&
                                    historyOwnersFinancialInfo?.assetsPrice?.[
                                      index
                                    ] &&
                                    'value' in
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ] &&
                                    historyOwnersFinancialInfo?.assetsPrice?.[
                                      index
                                    ]?.value !== null &&
                                    '#114684'
                                  }
                                  color={
                                    showHistory &&
                                    historyOwnersFinancialInfo?.assetsPrice?.[
                                      index
                                    ] &&
                                    'value' in
                                      historyOwnersFinancialInfo?.assetsPrice?.[
                                        index
                                      ] &&
                                    historyOwnersFinancialInfo?.assetsPrice?.[
                                      index
                                    ]?.value !== null &&
                                    'white'
                                  }
                                  onKeyDown={preventAlphabetsTyping}
                                  onWheel={(e) =>
                                    (e.target as HTMLInputElement).blur()
                                  }
                                />
                                {formik.touched.assetsPrice &&
                                  formik.errors.assetsPrice &&
                                  typeof formik.errors.assetsPrice[index] !==
                                    'string' &&
                                  formik.errors.assetsPrice[index] && // Ensure that the value is not null or undefined
                                  (formik.errors.assetsPrice[index] as any)
                                    ?.value && (
                                    <div
                                      style={{
                                        color: 'red',
                                        fontSize: '13px',
                                        textAlign: 'start',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {
                                        (
                                          formik.errors.assetsPrice[
                                            index
                                          ] as any
                                        ).value
                                      }
                                    </div>
                                  )}
                              </Box>
                              {index === 0 ? (
                                <Box
                                  display={{ base: 'none', md: 'flex' }}
                                  alignItems="center"
                                  fontSize="xl"
                                  marginTop="18px"
                                  onClick={() => handleAddBoxAssetsPrice(index)}
                                  cursor={showBox ? 'not-allowed' : 'pointer'}
                                  pointerEvents={showBox ? 'none' : 'auto'}
                                >
                                  <CiCirclePlus />
                                </Box>
                              ) : (
                                <Box
                                  display={{ base: 'none', md: 'flex' }}
                                  alignItems="center"
                                  fontSize="xl"
                                  marginTop="18px"
                                  onClick={() =>
                                    handleRemoveBoxAssetsPrice(index)
                                  }
                                  cursor={showBox ? 'not-allowed' : 'pointer'}
                                  pointerEvents={showBox ? 'none' : 'auto'}
                                >
                                  <CiCircleMinus />
                                </Box>
                              )}
                            </Box>
                          )
                        )}
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: 1, lg: 1 }}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={3}
                  >
                    <Text fontWeight="600" color="#114684" fontSize={15} mb={2}>
                      LIABILITIES
                    </Text>
                    <Box
                      display={'flex'}
                      gap={1}
                      flexWrap={'wrap'}
                      flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
                    >
                      <Box
                        flex={{ base: 1, md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                          mb={1}
                        >
                          <li>
                            <span style={{ fontWeight: 'bold' }}>
                              Personal residence mortgage
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </span>
                          </li>
                        </Text>
                        <Tooltip
                          label={
                            formik.values.liabilitiesResidence?.mortgage
                              ? formik.values.liabilitiesResidence?.mortgage
                              : 'Personal residence mortgage'
                          }
                          placement="top"
                          hasArrow
                        >
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="Text"
                            placeholder="Personal residence mortgage"
                            borderRadius="5px"
                            // onChange={formik.handleChange}
                            readOnly={true}
                            value={
                              formik.values.liabilitiesResidence?.mortgage ?? ''
                            }
                            name="liabilitiesResidence.mortgage"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            bgColor={
                              showHistory
                                ? historyOwnersFinancialInfo?.liabilitiesResidence &&
                                  'mortgage' in
                                    historyOwnersFinancialInfo?.liabilitiesResidence &&
                                  historyOwnersFinancialInfo
                                    ?.liabilitiesResidence?.mortgage !== null &&
                                  '#114684'
                                : '#f5f5f5'
                            }
                            color={
                              showHistory &&
                              historyOwnersFinancialInfo?.liabilitiesResidence &&
                              'mortgage' in
                                historyOwnersFinancialInfo?.liabilitiesResidence &&
                              historyOwnersFinancialInfo?.liabilitiesResidence
                                ?.mortgage !== null &&
                              'white'
                            }
                            cursor={'not-allowed'}
                          />
                        </Tooltip>
                        {formik.touched.liabilitiesResidence?.mortgage &&
                          formik.errors.liabilitiesResidence?.mortgage && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {formik.errors.liabilitiesResidence?.mortgage}
                            </div>
                          )}
                      </Box>
                      <Box
                        flex={{ base: 1, md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                          mb={1}
                        >
                          <li>
                            <span style={{ fontWeight: 'bold' }}>
                              $ Mortgage Value{' '}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </span>
                          </li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="number"
                          placeholder="$ Value personal residence mortgage"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          readOnly={showBox}
                          value={
                            formik.values.liabilitiesResidence?.value ?? ''
                          }
                          name="liabilitiesResidence.value"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          bgColor={
                            showHistory &&
                            historyOwnersFinancialInfo?.liabilitiesResidence &&
                            'value' in
                              historyOwnersFinancialInfo?.liabilitiesResidence &&
                            historyOwnersFinancialInfo?.liabilitiesResidence
                              ?.value !== null &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            historyOwnersFinancialInfo?.liabilitiesResidence &&
                            'value' in
                              historyOwnersFinancialInfo?.liabilitiesResidence &&
                            historyOwnersFinancialInfo?.liabilitiesResidence
                              ?.value !== null &&
                            'white'
                          }
                          onKeyDown={preventAlphabetsTyping}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        />
                        {formik.touched.liabilitiesResidence?.value &&
                          formik.errors.liabilitiesResidence?.value && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {formik.errors.liabilitiesResidence?.value}
                            </div>
                          )}
                      </Box>
                    </Box>
                    <Box>
                      {formik.values.liabilitiesRealEstate &&
                        formik.values.liabilitiesRealEstate?.map(
                          (data: any, index: any) => (
                            <Box
                              key={index}
                              display={{ base: 'block', md: 'flex' }}
                              flex={{ base: 1 }}
                              gap={1}
                            >
                              {/* <Box flex={{ base: "1", md: "1" }}>
                                        <Text
                                          fontWeight="600"
                                          color="#114684"
                                          textAlign="start"
                                          fontSize={13}
                                          mb={1}
                                        >
                                          <li>
                                            <span style={{ fontWeight: "bold" }}>
                                              {truncateText(
                                                " Additional real estate address",
                                                2
                                              )}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          </li>
                                        </Text>
                                        <Tooltip
                                          label=" Additional real estate address"
                                          placement="top"
                                          hasArrow
                                        >
                                          <Input
                                            fontSize={"13px"}
                                            type="Text"
                                            placeholder=" Additional real estate address"
                                            borderRadius="5px"
                                            onChange={formik.handleChange}
                                            readOnly={showBox}
                                            value={
                                              formik.values.liabilitiesRealEstate[
                                                index
                                              ]?.address || ""
                                            }
                                            name={`liabilitiesRealEstate[${index}].address`}
                                            focusBorderColor="#6666ff"
                                            borderColor={"#6666ff"}
                                            _hover={{ borderColor: "#6666ff" }}
                                            bgColor={
                                              showHistory &&
                                              historyOwnersFinancialInfo
                                                ?.liabilitiesRealEstate?.[
                                              index
                                              ] &&
                                              "address" in
                                              historyOwnersFinancialInfo
                                                ?.liabilitiesRealEstate?.[
                                              index
                                              ] &&
                                              "#114684"
                                            }
                                            color={
                                              showHistory &&
                                              historyOwnersFinancialInfo
                                                ?.liabilitiesRealEstate?.[
                                              index
                                              ] &&
                                              "address" in
                                              historyOwnersFinancialInfo
                                                ?.liabilitiesRealEstate?.[
                                              index
                                              ] &&
                                              "white"
                                            }
                                          />
                                        </Tooltip>

                                        {formik.touched.liabilitiesRealEstate &&
                                          formik.errors.liabilitiesRealEstate &&
                                          typeof formik.errors
                                            .liabilitiesRealEstate[index] !==
                                          "string" &&
                                          formik.errors.liabilitiesRealEstate[
                                          index
                                          ] && // Ensure that the value is not null or undefined
                                          (
                                            formik.errors.liabilitiesRealEstate[
                                            index
                                            ] as any
                                          )?.address && (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "13px",
                                                textAlign: "start",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {
                                                (
                                                  formik.errors
                                                    .liabilitiesRealEstate[
                                                  index
                                                  ] as any
                                                ).address
                                              }
                                            </div>
                                          )}
                                      </Box> */}
                              <Box flex={{ base: '1', md: '1' }}>
                                <Text
                                  fontWeight="600"
                                  color="#114684"
                                  textAlign="start"
                                  fontSize={13}
                                  mb={1}
                                >
                                  <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {/* {truncateText(
                                                "Additional real estate mortgage",
                                                2
                                              )} */}
                                      Other real estate mortgage
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </span>
                                  </li>
                                </Text>
                                <Tooltip
                                  label={
                                    formik.values.liabilitiesRealEstate[index]
                                      ?.mortgage
                                      ? formik.values.liabilitiesRealEstate[
                                          index
                                        ]?.mortgage
                                      : 'Other real estate mortgage'
                                  }
                                  placement="top"
                                  hasArrow
                                >
                                  <Input
                                    fontSize={'13px'}
                                    type="text"
                                    placeholder="Other real estate mortgage"
                                    borderRadius="5px"
                                    // onChange={formik.handleChange}
                                    readOnly={true}
                                    value={
                                      formik.values.liabilitiesRealEstate[index]
                                        ?.mortgage || ''
                                    }
                                    name={`liabilitiesRealEstate[${index}].mortgage`}
                                    focusBorderColor="#7f7f7f"
                                    borderColor={'#7f7f7f'}
                                    _hover={{ borderColor: '#7f7f7f' }}
                                    bgColor={
                                      showHistory
                                        ? historyOwnersFinancialInfo
                                            ?.liabilitiesRealEstate?.[index] &&
                                          'mortgage' in
                                            historyOwnersFinancialInfo
                                              ?.liabilitiesRealEstate?.[
                                              index
                                            ] &&
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesRealEstate?.[index]
                                            ?.mortgage !== null &&
                                          '#114684'
                                        : '#f5f5f5'
                                    }
                                    color={
                                      showHistory &&
                                      historyOwnersFinancialInfo
                                        ?.liabilitiesRealEstate?.[index] &&
                                      'mortgage' in
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesRealEstate?.[index] &&
                                      historyOwnersFinancialInfo
                                        ?.liabilitiesRealEstate?.[index]
                                        ?.mortgage !== null &&
                                      'white'
                                    }
                                    cursor={'not-allowed'}
                                  />
                                </Tooltip>
                                {formik.touched.liabilitiesRealEstate &&
                                  formik.errors.liabilitiesRealEstate &&
                                  typeof formik.errors.liabilitiesRealEstate[
                                    index
                                  ] !== 'string' &&
                                  formik.errors.liabilitiesRealEstate[index] && // Ensure that the value is not null or undefined
                                  (
                                    formik.errors.liabilitiesRealEstate[
                                      index
                                    ] as any
                                  )?.mortgage && (
                                    <div
                                      style={{
                                        color: 'red',
                                        fontSize: '13px',
                                        textAlign: 'start',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {
                                        (
                                          formik.errors.liabilitiesRealEstate[
                                            index
                                          ] as any
                                        ).mortgage
                                      }
                                    </div>
                                  )}
                              </Box>
                              <Box
                                flex={{ base: '1', md: '1' }}
                                display={'flex'}
                                justifyContent={'space-between'}
                                gap={2}
                                alignItems={'center'}
                              >
                                <Box flex={{ base: '1', md: '1' }}>
                                  <Text
                                    fontWeight="600"
                                    color="#114684"
                                    textAlign="start"
                                    fontSize={13}
                                    mb={1}
                                  >
                                    <li>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {/* {truncateText(
                                                "Additional real estate mortgage $ value",
                                                2
                                              )} */}
                                        $ Mortgage Value
                                        {/* <span style={{ color: "red" }}>*</span> */}
                                      </span>
                                    </li>
                                  </Text>
                                  <Tooltip
                                    label="$ Mortgage Value"
                                    placement="top"
                                    hasArrow
                                  >
                                    <Input
                                      fontSize={'13px'}
                                      type="number"
                                      placeholder="Other real estate mortgage $ value"
                                      borderRadius="5px"
                                      onChange={formik.handleChange}
                                      readOnly={showBox}
                                      value={
                                        formik.values.liabilitiesRealEstate[
                                          index
                                        ]?.value ?? ''
                                      }
                                      name={`liabilitiesRealEstate[${index}].value`}
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{ borderColor: '#7f7f7f' }}
                                      bgColor={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesRealEstate?.[index] &&
                                        'value' in
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesRealEstate?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesRealEstate?.[index]
                                          ?.value !== null &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesRealEstate?.[index] &&
                                        'value' in
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesRealEstate?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesRealEstate?.[index]
                                          ?.value !== null &&
                                        'white'
                                      }
                                      onKeyDown={preventAlphabetsTyping}
                                      onWheel={(e) =>
                                        (e.target as HTMLInputElement).blur()
                                      }
                                    />
                                  </Tooltip>
                                  {formik.touched.liabilitiesRealEstate &&
                                    formik.errors.liabilitiesRealEstate &&
                                    typeof formik.errors.liabilitiesRealEstate[
                                      index
                                    ] !== 'string' &&
                                    formik.errors.liabilitiesRealEstate[
                                      index
                                    ] && // Ensure that the value is not null or undefined
                                    (
                                      formik.errors.liabilitiesRealEstate[
                                        index
                                      ] as any
                                    )?.value && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '13px',
                                          textAlign: 'start',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {
                                          (
                                            formik.errors.liabilitiesRealEstate[
                                              index
                                            ] as any
                                          ).value
                                        }
                                      </div>
                                    )}
                                </Box>
                                {index === 0 ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop="18px"
                                    onClick={() => handleAddBox(index)}
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCirclePlus />
                                  </Box>
                                ) : (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop="18px"
                                    onClick={() => handleRemoveBox(index)}
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCircleMinus />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          )
                        )}
                    </Box>
                    <Box>
                      {formik.values.liabilitiesPrice &&
                        formik.values.liabilitiesPrice?.map(
                          (data: any, index: any) => (
                            <Box
                              key={index}
                              display={{ base: 'block', md: 'flex' }}
                              flex={{ base: 1 }}
                              gap={1}
                            >
                              <Box
                                display={{ base: 'block', md: 'flex' }}
                                flex={{ base: 1 }}
                                gap={3}
                              >
                                <Box
                                  display={{ base: 'flex' }}
                                  justifyContent={'space-between'}
                                  gap={3}
                                  alignItems={'center'}
                                  flex={{ base: '1', md: '1' }}
                                >
                                  <Box flex={{ base: '1', md: '1' }}>
                                    <Text
                                      fontWeight="600"
                                      color="#114684"
                                      textAlign="start"
                                      fontSize={13}
                                      mb={1}
                                    >
                                      <li>
                                        <span style={{ fontWeight: 'bold' }}>
                                          {truncateText('Select Dropdown', 2)}

                                          {/* <span style={{ color: "red" }}>*</span> */}
                                        </span>
                                      </li>
                                    </Text>
                                    {/* <Tooltip
                                            label="Select dropdown"
                                            placement="top"
                                            hasArrow
                                          >
                                          </Tooltip> */}
                                    <Select
                                      // onChange={formik.handleChange}
                                      onChange={(e) => {
                                        if (e.target.value !== 'Others') {
                                          formik.setValues(
                                            (prevValues: any) => {
                                              let newValues = {
                                                ...prevValues,
                                              };
                                              let liabilitiesPrice =
                                                newValues?.liabilitiesPrice ||
                                                [];
                                              let updatedLiabilitiesPrice =
                                                liabilitiesPrice?.map(
                                                  (item: any, i: number) => {
                                                    if (index === i) {
                                                      if (
                                                        liabilitiesPrice
                                                          .map(
                                                            (item: any) =>
                                                              item.type
                                                          )
                                                          .includes(
                                                            e.target.value
                                                          )
                                                      ) {
                                                        let matchItem =
                                                          liabilitiesPrice?.filter(
                                                            (asset: any) =>
                                                              asset.type ===
                                                              e.target.value
                                                          )[0];
                                                        delete matchItem?.otherLiabilityType;
                                                        return {
                                                          ...matchItem,
                                                        };
                                                      } else {
                                                        delete item?.otherLiabilityType;
                                                        return {
                                                          ...item,
                                                          type: e.target.value,
                                                        };
                                                      }
                                                    }
                                                    if (
                                                      item.type ===
                                                      e.target.value
                                                    ) {
                                                      return liabilitiesPrice[
                                                        index
                                                      ];
                                                    } else {
                                                      return item;
                                                    }
                                                  }
                                                );

                                              return {
                                                ...newValues,
                                                liabilitiesPrice:
                                                  updatedLiabilitiesPrice,
                                              };
                                            }
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `liabilitiesPrice[${index}].type`,
                                            e.target.value
                                          );
                                        }
                                      }}
                                      value={
                                        formik.values.liabilitiesPrice[index]
                                          .type ?? ''
                                      }
                                      name={`liabilitiesPrice[${index}].type`}
                                      fontSize={'13px'}
                                      w="100%"
                                      borderRadius="5px"
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{ borderColor: '#7f7f7f' }}
                                      isDisabled={showBox}
                                      _disabled={{ opacity: 1 }}
                                      bgColor={
                                        showHistory &&
                                        historyOwnersFinancialInfo &&
                                        'liabilitiesPrice' in
                                          historyOwnersFinancialInfo &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index] &&
                                        'type' in
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesPrice?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index]?.type !==
                                          null &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        historyOwnersFinancialInfo &&
                                        'liabilitiesPrice' in
                                          historyOwnersFinancialInfo &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index] &&
                                        'type' in
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesPrice?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index]?.type !==
                                          null &&
                                        'white'
                                      }
                                    >
                                      {liabilitiesPriceOptions.map(
                                        (option: string, i: number) => {
                                          const typesPresentLiabilities =
                                            formik.values.liabilitiesPrice.filter(
                                              (
                                                item: any,
                                                assetdIndex: number
                                              ) => assetdIndex < index
                                            );
                                          const typesPresentarr =
                                            typesPresentLiabilities.map(
                                              (item) => item.type
                                            );
                                          if (
                                            !typesPresentarr.includes(option)
                                          ) {
                                            return (
                                              <option
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            );
                                          }

                                          // <option key={option} value={option} disabled={(option !== 'Others' && formik.values.liabilitiesPrice.some(item => item.type === option))}>{option}</option>
                                        }
                                      )}
                                      <option
                                        key={
                                          liabilitiesPriceOptions?.length + 1
                                        }
                                        value={'Others'}
                                      >
                                        Others
                                      </option>
                                    </Select>

                                    {formik.touched.liabilitiesPrice &&
                                      formik.errors.liabilitiesPrice &&
                                      typeof formik.errors.liabilitiesPrice[
                                        index
                                      ] !== 'string' &&
                                      formik.errors.liabilitiesPrice[index] && // Ensure that the value is not null or undefined
                                      (
                                        formik.errors.liabilitiesPrice[
                                          index
                                        ] as any
                                      )?.type && (
                                        <div
                                          style={{
                                            color: 'red',
                                            fontSize: '13px',
                                            textAlign: 'start',
                                            fontWeight: '500',
                                          }}
                                        >
                                          {
                                            (
                                              formik.errors.liabilitiesPrice[
                                                index
                                              ] as any
                                            ).type
                                          }
                                        </div>
                                      )}
                                  </Box>
                                  {index === 0 ? (
                                    <Box
                                      display={{ base: 'flex', md: 'none' }}
                                      alignItems="center"
                                      fontSize="xl"
                                      marginTop="18px"
                                      onClick={() =>
                                        handleAddBoxLiabilitiesPrice(index)
                                      }
                                      cursor={
                                        showBox ? 'not-allowed' : 'pointer'
                                      }
                                      pointerEvents={showBox ? 'none' : 'auto'}
                                    >
                                      <CiCirclePlus />
                                    </Box>
                                  ) : (
                                    <Box
                                      display={{ base: 'flex', md: 'none' }}
                                      alignItems="center"
                                      fontSize="xl"
                                      marginTop="18px"
                                      onClick={() =>
                                        handleRemoveBoxLiabilitiesPrice(index)
                                      }
                                      cursor={
                                        showBox ? 'not-allowed' : 'pointer'
                                      }
                                      pointerEvents={showBox ? 'none' : 'auto'}
                                    >
                                      <CiCircleMinus />
                                    </Box>
                                  )}
                                </Box>
                                {formik.values?.liabilitiesPrice?.[index]
                                  ?.type === 'Others' && (
                                  <Box flex={{ base: '1', md: '1' }}>
                                    <Text
                                      fontWeight="600"
                                      color="#114684"
                                      textAlign="start"
                                      fontSize={13}
                                      mb={1}
                                    >
                                      <li>
                                        <span style={{ fontWeight: 'bold' }}>
                                          Type{' '}
                                          {/* <span style={{ color: "red" }}>*</span> */}
                                        </span>
                                      </li>
                                    </Text>
                                    <Input
                                      fontSize={'13px'}
                                      w="100%"
                                      type="text"
                                      placeholder="Liability Type"
                                      borderRadius="5px"
                                      onChange={formik.handleChange}
                                      readOnly={showBox}
                                      value={
                                        formik.values.liabilitiesPrice[index]
                                          ?.otherLiabilityType || ''
                                      }
                                      name={`liabilitiesPrice[${index}].otherLiabilityType`}
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{
                                        borderColor: '#7f7f7f',
                                      }}
                                      bgColor={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index] &&
                                        'otherLiabilityType' in
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesPrice?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index]
                                          ?.otherLiabilityType !== null &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index] &&
                                        'otherLiabilityType' in
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesPrice?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index]
                                          ?.otherLiabilityType !== null &&
                                        'white'
                                      }
                                    />
                                    {formik.touched.liabilitiesPrice &&
                                      formik.errors.liabilitiesPrice &&
                                      typeof formik.errors.liabilitiesPrice[
                                        index
                                      ] !== 'string' &&
                                      formik.errors.liabilitiesPrice[index] && // Ensure that the value is not null or undefined
                                      (
                                        formik.errors.liabilitiesPrice[
                                          index
                                        ] as any
                                      )?.otherLiabilityType && (
                                        <div
                                          style={{
                                            color: 'red',
                                            fontSize: '13px',
                                            textAlign: 'start',
                                            fontWeight: '500',
                                          }}
                                        >
                                          {
                                            (
                                              formik.errors.liabilitiesPrice[
                                                index
                                              ] as any
                                            ).otherLiabilityType
                                          }
                                        </div>
                                      )}
                                  </Box>
                                )}
                                <Box flex={{ base: '1', md: '1' }}>
                                  <Text
                                    fontWeight="600"
                                    color="#114684"
                                    textAlign="start"
                                    fontSize={13}
                                    mb={1}
                                  >
                                    <li>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {truncateText('$ value', 2)}
                                        {/* <span style={{ color: "red" }}>*</span> */}
                                      </span>
                                    </li>
                                  </Text>
                                  <Tooltip
                                    label="$ value"
                                    placement="top"
                                    hasArrow
                                  >
                                    <Input
                                      fontSize={'13px'}
                                      w="100%"
                                      type="number"
                                      placeholder="$ value"
                                      borderRadius="5px"
                                      onChange={formik.handleChange}
                                      readOnly={showBox}
                                      name={`liabilitiesPrice[${index}].value`}
                                      value={
                                        formik.values.liabilitiesPrice[index]
                                          ?.value ?? ''
                                      }
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{
                                        borderColor: '#7f7f7f',
                                      }}
                                      bgColor={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index] &&
                                        'value' in
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesPrice?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index]?.value !==
                                          null &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index] &&
                                        'value' in
                                          historyOwnersFinancialInfo
                                            ?.liabilitiesPrice?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.liabilitiesPrice?.[index]?.value !==
                                          null &&
                                        'white'
                                      }
                                      onKeyDown={preventAlphabetsTyping}
                                      onWheel={(e) =>
                                        (e.target as HTMLInputElement).blur()
                                      }
                                    />
                                  </Tooltip>
                                  {formik.touched.liabilitiesPrice &&
                                    formik.errors.liabilitiesPrice &&
                                    typeof formik.errors.liabilitiesPrice[
                                      index
                                    ] !== 'string' &&
                                    formik.errors.liabilitiesPrice[index] && // Ensure that the value is not null or undefined
                                    (
                                      formik.errors.liabilitiesPrice[
                                        index
                                      ] as any
                                    )?.value && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '13px',
                                          textAlign: 'start',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {
                                          (
                                            formik.errors.liabilitiesPrice[
                                              index
                                            ] as any
                                          ).value
                                        }
                                      </div>
                                    )}
                                </Box>
                                {index === 0 ? (
                                  <Box
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop="18px"
                                    onClick={() =>
                                      handleAddBoxLiabilitiesPrice(index)
                                    }
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCirclePlus />
                                  </Box>
                                ) : (
                                  <Box
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop="18px"
                                    onClick={() =>
                                      handleRemoveBoxLiabilitiesPrice(index)
                                    }
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCircleMinus />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          )
                        )}
                    </Box>
                  </Box>
                </Box>

                <Box width={{ base: '100%', md: '50%' }}>
                  {formik.values.businessInterests &&
                    formik.values.businessInterests?.map(
                      (data: any, index: any) => (
                        <>
                          <Box
                            key={index}
                            display={{ base: 'block', md: 'flex' }}
                            flex={{ base: 1 }}
                            flexDirection={'column'}
                            gap={3}
                          >
                            <Box
                              display={'flex'}
                              flex={{ base: 1 }}
                              gap={3}
                              alignItems={'space-between'}
                              justifyContent={'space-between'}
                            >
                              <Box flex={{ base: '1', md: '1' }}>
                                <Text
                                  fontWeight="600"
                                  color="#114684"
                                  textAlign="start"
                                  fontSize={13}
                                  mb={1}
                                >
                                  <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {/* {truncateText(
                                                "Business interests (Legal business name)",
                                                2
                                              )} */}
                                      Business interests (Legal business name)
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </span>
                                  </li>
                                </Text>
                                <Tooltip
                                  label="Business interests (Legal business name)"
                                  placement="top"
                                  hasArrow
                                >
                                  <Input
                                    fontSize={'13px'}
                                    w="100%"
                                    type="Text"
                                    placeholder="Business interests (Legal business name)"
                                    borderRadius="5px"
                                    onChange={formik.handleChange}
                                    readOnly={showBox}
                                    name={`businessInterests[${index}].legalName`}
                                    value={
                                      formik.values.businessInterests[index]
                                        ?.legalName || ''
                                    }
                                    focusBorderColor="#7f7f7f"
                                    borderColor={'#7f7f7f'}
                                    _hover={{
                                      borderColor: '#7f7f7f',
                                    }}
                                    bgColor={
                                      showHistory &&
                                      historyOwnersFinancialInfo
                                        ?.businessInterests?.[index] &&
                                      'legalName' in
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index] &&
                                      historyOwnersFinancialInfo
                                        ?.businessInterests?.[index]
                                        ?.legalName !== null &&
                                      '#114684'
                                    }
                                    color={
                                      showHistory &&
                                      historyOwnersFinancialInfo
                                        ?.businessInterests?.[index] &&
                                      'legalName' in
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index] &&
                                      historyOwnersFinancialInfo
                                        ?.businessInterests?.[index]
                                        ?.legalName !== null &&
                                      'white'
                                    }
                                  />
                                </Tooltip>

                                {formik.touched.businessInterests &&
                                  formik.errors.businessInterests &&
                                  typeof formik.errors.businessInterests[
                                    index
                                  ] !== 'string' &&
                                  formik.errors.businessInterests[index] && // Ensure that the value is not null or undefined
                                  (
                                    formik.errors.businessInterests[
                                      index
                                    ] as any
                                  )?.legalName && (
                                    <div
                                      style={{
                                        color: 'red',
                                        fontSize: '13px',
                                        textAlign: 'start',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {
                                        (
                                          formik.errors.businessInterests[
                                            index
                                          ] as any
                                        ).legalName
                                      }
                                    </div>
                                  )}
                              </Box>
                              {index === 0 ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  fontSize="xl"
                                  marginTop="18px"
                                  onClick={() => handleAddBox6(index)}
                                  cursor={showBox ? 'not-allowed' : 'pointer'}
                                  pointerEvents={showBox ? 'none' : 'auto'}
                                >
                                  <CiCirclePlus />
                                </Box>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  fontSize="xl"
                                  marginTop="18px"
                                  onClick={() => handleRemoveBox6(index)}
                                  cursor={showBox ? 'not-allowed' : 'pointer'}
                                  pointerEvents={showBox ? 'none' : 'auto'}
                                >
                                  <CiCircleMinus />
                                </Box>
                              )}
                            </Box>
                            <Box
                              display={{
                                base: 'block',
                                md: 'flex',
                              }}
                              flex={{ base: 1 }}
                              gap={3}
                              width={'calc(100% - 20px)'}
                            >
                              <Box
                                flex={{ base: '1', md: '1' }}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'space-between'}
                              >
                                <Text
                                  fontWeight="600"
                                  color="#114684"
                                  textAlign="start"
                                  fontSize={13}
                                  mb={1}
                                >
                                  <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {/* {truncateText(
                                                "Total Value of the Business",
                                                2
                                              )} */}
                                      Total Value of the Business
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </span>
                                  </li>
                                </Text>
                                <Box>
                                  <Tooltip
                                    label="Total Value of the Business"
                                    placement="top"
                                    hasArrow
                                  >
                                    <Input
                                      fontSize={'13px'}
                                      w="100%"
                                      type="number"
                                      placeholder="$ Total Value of the Business"
                                      borderRadius="5px"
                                      onChange={formik.handleChange}
                                      readOnly={showBox}
                                      name={`businessInterests[${index}].value`}
                                      value={
                                        formik.values.businessInterests[index]
                                          ?.value ?? ''
                                      }
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{
                                        borderColor: '#7f7f7f',
                                      }}
                                      bgColor={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index] &&
                                        'value' in
                                          historyOwnersFinancialInfo
                                            ?.businessInterests?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index]
                                          ?.value !== null &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index] &&
                                        'value' in
                                          historyOwnersFinancialInfo
                                            ?.businessInterests?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index]
                                          ?.value !== null &&
                                        'white'
                                      }
                                      onKeyDown={preventAlphabetsTyping}
                                      onWheel={(e) =>
                                        (e.target as HTMLInputElement).blur()
                                      }
                                    />
                                  </Tooltip>
                                  {formik.touched.businessInterests &&
                                    formik.errors.businessInterests &&
                                    typeof formik.errors.businessInterests[
                                      index
                                    ] !== 'string' &&
                                    formik.errors.businessInterests[index] && // Ensure that the value is not null or undefined
                                    (
                                      formik.errors.businessInterests[
                                        index
                                      ] as any
                                    )?.value && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '13px',
                                          textAlign: 'start',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {
                                          (
                                            formik.errors.businessInterests[
                                              index
                                            ] as any
                                          ).value
                                        }
                                      </div>
                                    )}
                                </Box>
                              </Box>
                              <Box
                                flex={{ base: '1', md: '1' }}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'space-between'}
                              >
                                <Text
                                  fontWeight="600"
                                  color="#114684"
                                  textAlign="start"
                                  fontSize={13}
                                  mb={1}
                                >
                                  <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {/* {truncateText(
                                                "Your Ownership %",
                                                2
                                              )} */}
                                      Your Ownership %
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </span>
                                  </li>
                                </Text>
                                <Box>
                                  <Tooltip
                                    label="Your Ownership %"
                                    placement="top"
                                    hasArrow
                                  >
                                    <Input
                                      fontSize={'13px'}
                                      w="100%"
                                      type="number"
                                      placeholder="Your Ownership %"
                                      borderRadius="5px"
                                      onChange={formik.handleChange}
                                      readOnly={showBox}
                                      max={100}
                                      name={`businessInterests[${index}].ownershipPercentage`}
                                      value={
                                        formik.values.businessInterests[index]
                                          ?.ownershipPercentage ?? ''
                                      }
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{
                                        borderColor: '#7f7f7f',
                                      }}
                                      bgColor={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index] &&
                                        'ownershipPercentage' in
                                          historyOwnersFinancialInfo
                                            ?.businessInterests?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index]
                                          ?.ownershipPercentage !== null &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index] &&
                                        'ownershipPercentage' in
                                          historyOwnersFinancialInfo
                                            ?.businessInterests?.[index] &&
                                        historyOwnersFinancialInfo
                                          ?.businessInterests?.[index]
                                          ?.ownershipPercentage !== null &&
                                        'white'
                                      }
                                      onKeyDown={preventAlphabetsTyping}
                                      onWheel={(e) =>
                                        (e.target as HTMLInputElement).blur()
                                      }
                                    />
                                  </Tooltip>
                                  {formik.touched.businessInterests &&
                                    formik.errors.businessInterests &&
                                    typeof formik.errors.businessInterests[
                                      index
                                    ] !== 'string' &&
                                    formik.errors.businessInterests[index] && // Ensure that the value is not null or undefined
                                    (
                                      formik.errors.businessInterests[
                                        index
                                      ] as any
                                    )?.ownershipPercentage && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '13px',
                                          textAlign: 'start',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {
                                          (
                                            formik.errors.businessInterests[
                                              index
                                            ] as any
                                          ).ownershipPercentage
                                        }
                                      </div>
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )
                    )}
                </Box>

                <Box
                  display={'grid'}
                  gridTemplateColumns={'repeat(auto-fill, minmax(270px, 1fr))'}
                  gap={5}
                >
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          Total assets{' '}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      </li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="$ Total assets"
                      borderRadius="5px"
                      name="totalAssets"
                      value={formik.values.totalAssets ?? ''}
                      onChange={formik.handleChange}
                      readOnly={true}
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      bgColor={
                        showHistory &&
                        historyOwnersFinancialInfo &&
                        'totalAssets' in historyOwnersFinancialInfo &&
                        historyOwnersFinancialInfo?.totalAssets !== null &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        historyOwnersFinancialInfo &&
                        'totalAssets' in historyOwnersFinancialInfo &&
                        historyOwnersFinancialInfo?.totalAssets !== null &&
                        'white'
                      }
                    />
                    {formik.touched.totalAssets &&
                      formik.errors.totalAssets && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.totalAssets}
                        </div>
                      )}
                  </Box>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          Total liabilities{' '}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      </li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="$ Total liabilities"
                      borderRadius="5px"
                      name="totalLiabilities"
                      value={formik.values.totalLiabilities || ''}
                      onChange={formik.handleChange}
                      readOnly={true}
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      bgColor={
                        showHistory &&
                        historyOwnersFinancialInfo &&
                        'totalLiabilities' in historyOwnersFinancialInfo &&
                        historyOwnersFinancialInfo?.totalLiabilities !== null &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        historyOwnersFinancialInfo &&
                        'totalLiabilities' in historyOwnersFinancialInfo &&
                        historyOwnersFinancialInfo?.totalLiabilities !== null &&
                        'white'
                      }
                    />
                    {formik.touched.totalLiabilities &&
                      formik.errors.totalLiabilities && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.totalLiabilities}
                        </div>
                      )}
                  </Box>
                </Box>

                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          Personal net worth{' '}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      </li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="$ Total personal net worth"
                      borderRadius="5px"
                      name="personalNetWorth"
                      value={formik.values.personalNetWorth ?? ''}
                      onChange={formik.handleChange}
                      readOnly={true}
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      bgColor={
                        showHistory &&
                        historyOwnersFinancialInfo &&
                        'personalNetWorth' in historyOwnersFinancialInfo &&
                        historyOwnersFinancialInfo?.personalNetWorth !== null &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        historyOwnersFinancialInfo &&
                        'personalNetWorth' in historyOwnersFinancialInfo &&
                        historyOwnersFinancialInfo?.personalNetWorth !== null &&
                        'white'
                      }
                    />
                    {formik.touched.personalNetWorth &&
                      formik.errors.personalNetWorth && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.personalNetWorth}
                        </div>
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>

            {(isBrokerUser || isOwnerUser) && (
              <Box
                display={'flex'}
                justifyContent={'end'}
                marginTop={'20px'}
                gap={2}
              >
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  onClick={() =>
                    handleAssignTo(
                      isOwnerUser ? 'broker' : 'owner',
                      (isOwnerUser
                        ? financialInfoFormData?.ownerId
                        : ownerDetails?.ownerId) || 0
                    )
                  }
                  type="button"
                  isDisabled={
                    submitForm ||
                    (!!financialInfoFormData?.assignToOwner && !isOwnerUser) ||
                    showBox
                  }
                >
                  {isOwnerUser ? 'Return to Broker' : 'Assign to Owner'}
                </Button>
                {/* {isOwnerUser && (
                      <Button
                        bg={"#114684"}
                        color={"white"}
                        _hover={{ bg: "#114684", color: "white" }}
                        type="button"
                        onClick={handleUpdateByOwner}
                      >
                        Update
                      </Button>
                    )} */}
              </Box>
            )}
            {ownersCount - 1 === index && (
              <Box
                display={'flex'}
                justifyContent={'end'}
                marginTop={'20px'}
                gap={2}
              >
                {!showBox && !isBrokerUser && (
                  <Button
                    bg={'#114684'}
                    color={'white'}
                    _hover={{ bg: '#114684', color: 'white' }}
                    onClick={
                      userType === 'broker' &&
                      onGoingFormData?.assignToApplicant
                        ? () => {}
                        : () => OnHandelReturnToSender()
                    }
                    type={
                      userType === 'broker' &&
                      onGoingFormData?.assignToApplicant
                        ? 'button'
                        : 'submit'
                    }
                    // disabled={submitForm}
                    // isDisabled={
                    //   userType === 'broker' &&
                    //   onGoingFormData?.assignToApplicant
                    // }
                  >
                    {isApplicantUser
                      ? 'Return to Broker'
                      : userType === 'broker' &&
                          onGoingFormData?.assignToApplicant
                        ? 'Edit - Rescind Assignment from Applicant'
                        : 'Assign to applicant'}
                  </Button>
                )}
                {!showBox && (
                  <Box>
                    {fetchId ? (
                      <Button
                        bg={'#114684'}
                        color={'white'}
                        _hover={{ bg: '#114684', color: 'white' }}
                        type="button"
                        onClick={() => setSubmitForm(true)}
                        isDisabled={submitForm}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        bg={'#114684'}
                        color={'white'}
                        _hover={{ bg: '#114684', color: 'white' }}
                        type="button"
                        onClick={() => setSubmitForm(true)}
                        isDisabled={submitForm}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </form>
        </Container>
      </>
    );
  }
);

export default PersonalFinancialInfo;
