import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CiCircleInfo } from 'react-icons/ci';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppSolidButton } from 'src/Component/Common/Micro/AppButtons';
import { useAppToast } from 'src/hooks';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { BASEURL } from '../../../App';
import {
  ApplicantBrokerage,
  Applicantpermission,
  checkUserProfileStatus,
  InitialApplication,
} from '../../../Redux/Broker/Dashboard/BrokerageDashboard';
import { fetchDashboardDetails } from '../../../Redux/Notification/Notification';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../Redux/Store';
import { socket } from '../../../Socket';

function BrokerDashboard() {
  const Toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const [selectedBrokerageId, setSelectedBrokerageId] = useState('');
  const [isUpdateApplication, setIsUpdateApplication] = useState(false);
  const [autoPopulateModalOpen, setAutoPopulateModalOpen] = useState(false);
  const [qboModal, setQboModal] = useState(false);
  // const [data, setData] = useState<boolean | undefined | null>(null);
  const [isloadingApp, setIsloadingApp] = useState<boolean>(false);
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [isOngoingDataLoading, setIsOngoingDataLoading] = useState(false);
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const userData = useSelector(
    (state: RootState) => state.brokerageDashboard.user
  );
  const toast = useAppToast();

  useEffect(() => {
    dispatch(ApplicantBrokerage());
  }, []);

  useEffect(() => {
    const selectApplicationData = applicantOptions?.find(
      (item: any) => item?.applicantId === Number(selectedBrokerageId)
    );
    if (selectApplicationData) {
      setApplicationId(selectApplicationData?.ongoingApplicationId);
    }
  }, [selectedBrokerageId]);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData, user, error } =
      brokerageDashboard;
    switch (status) {
      case 'loading':
        break;
      case 'succeed':
        if (
          type === 'GET_APPLICANT_BY_BROKERAGE' &&
          !!applicantsByBrokrageData?.length
        ) {
          setApplicantOptions(applicantsByBrokrageData);
        }
        if (type === 'GET_APPLICANT_PROFILE_STATUS' && selectedBrokerageId) {
          if (brokerageDashboard.selectedApplicantData?.isProfileCompleted) {
            fetchData(selectedBrokerageId);
          }
        }
        if (
          (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE' ||
            type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') &&
          selectedBrokerageId
        ) {
          toast({
            title: user?.data?.msg,
            status: 'success',
          });
          navigate(`/application/${user?.data?.applicationId}`);
        }
        break;
      case 'failed':
        if (
          (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE' ||
            type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') &&
          selectedBrokerageId
        ) {
          toast({
            title: error,
            status: 'error',
          });
        }
        break;

      default:
        break;
    }
  }, [brokerageDashboard.status, selectedBrokerageId]);

  useEffect(() => {
    // 1
    socket.on('permissionGranted', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 2
    socket.on('permissionDenied', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 3
    socket.on('updateFormByApplicant', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 4
    // socket.on("updateFormByAccountant", (data) => {
    //   Toast({
    //     title: data.message,
    //     status: "success",
    //     isClosable: true,
    //     duration: 3000,
    //     position: "top-right",
    //   });
    // });

    // 5
    // socket.on("updateFormByOwner", (data) => {
    //   Toast({
    //     title: data.message,
    //     status: "success",
    //     isClosable: true,
    //     duration: 3000,
    //     position: "top-right",
    //   });
    // });

    // Cleanup function
    return () => {
      socket.off('permissionGranted');
      socket.off('permissionDenied');
      socket.off('updateFormByApplicant');
      // socket.off("updateFormByAccountant");
      // socket.off("updateFormByOwner");
    };
  }, []);

  const fetchData = async (id: number | string) => {
    try {
      setIsOngoingDataLoading(true);
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(
        `${BASEURL}/application/ongoing/${id}`,
        config
      );

      if (!response.ok) {
        setIsOngoingDataLoading(false);
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      // const OngoingData = result?.data?.applicationId ? true : false;
      // setData(OngoingData);
      setApplicationId(result?.data?.applicationId);
      setIsOngoingDataLoading(false);
    } catch (error) {
      setIsOngoingDataLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleBrokerageSelect = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    setSelectedBrokerageId((prevSelectedId) => selectedId);

    if (selectedId) {
      setIsloadingApp(true);
      dispatch(checkUserProfileStatus(selectedId));

      setIsloadingApp(false);
    }
  };

  const confirmAutoPopulate = () => {
    if (applicationId) {
      navigate(`/application/${applicationId}`);
    } else {
      setIsUpdateApplication(true);
      try {
        dispatch(
          Applicantpermission({
            applicantId: parseFloat(selectedBrokerageId),
          }) as any
        );
      } catch (error: any) {
        Toast({
          title: error.message,
          status: 'error',
          isClosable: true,
          duration: 1000,
          position: 'top-right',
        });
      }
      setIsUpdateApplication(false);
    }
  };
  const declinedAutoPopulate = () => {
    setIsUpdateApplication(true);
    try {
      dispatch(
        InitialApplication({
          id: parseFloat(selectedBrokerageId),
        }) as any
      );
    } catch (error: any) {
      Toast({
        title: error.message,
        status: 'error',
        isClosable: true,
        duration: 1000,
        position: 'top-right',
      });
    }
    setIsUpdateApplication(false);
  };

  const defaultColor = '#ffa011';
  useEffect(() => {
    fetchDashboardData();
  }, []);
  const [applicationCount, setApplicationCount] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchDashboardData = async () => {
    try {
      setIsLoading(true);
      const actionResult = await dispatch(
        fetchDashboardDetails({ applicationFilter: '' }) as any
      );
      const response = actionResult?.payload;
      if (actionResult?.payload?.status === 401) {
        navigate('/login');
      } else {
        if (
          response &&
          Object.keys(response?.data?.applicationCounts || {}).length
        ) {
          setApplicationCount(response.data.applicationCounts);
        } else {
          setApplicationCount({});
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const renderCardData = () => {
    // Ensure `applicationCount` is defined and not null
    // if (!applicationCount) return null;
    if (!applicationCount) return null;
    const handleNavigation = (key: string) => {
      setIsLoading(true);
      switch (key) {
        case 'Drafts':
          navigate('/applications/drafts', {
            state: { status: key },
          });
          break;
        // case 'Awaiting Consent':
        //   navigate('/applications/awaiting-consent', {
        //     state: { status: key },
        //   });
        //   break;
        case 'Awaiting Input':
          navigate('/applications/awaiting-input', { state: { status: key } });
          break;
        case 'Application Submitted. Awaiting Underwriting':
          navigate('/applications/awaiting-underwriting', {
            state: { status: key },
          });
          break;
        case 'Approved by Underwriter':
          navigate('/applications/approved-by-underwriter', {
            state: { status: key },
          });
          break;
        case 'Declined by Underwriter':
          navigate('/applications/declined-by-underwriter', {
            state: { status: key },
          });
          break;
        default:
          break;
      }
    };
    if (Object.keys(applicationCount).length) {
      return (
        <Box
          display={'grid'}
          gridTemplateColumns={'repeat(auto-fill, minmax(270px, 1fr))'}
          gap={5}
          p={4}
        >
          {Object.entries(applicationCount)
            ?.filter(
              ([key, value]: [string, any]) => key !== 'Awaiting Consent'
            )
            ?.map(([key, value]) => (
              <Box
                key={key}
                boxShadow="md"
                cursor="pointer"
                borderWidth="1px"
                borderColor={defaultColor}
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: 'translateY(-1px)' }}
                onClick={() => handleNavigation(key)}
              >
                <Flex height={'100%'}>
                  <Text
                    p="3"
                    bg={defaultColor}
                    fontWeight="600"
                    borderRightWidth="1px"
                    flex={10}
                  >
                    {key}
                  </Text>
                  <Text
                    p="3"
                    minW={'60px'}
                    flex={1}
                    textAlign="center"
                    fontWeight="600"
                  >
                    {value as any}
                  </Text>
                </Flex>
              </Box>
            ))}
        </Box>
      );
    } else {
      return (
        <Text
          p="2"
          bg={defaultColor}
          color="#000"
          m="2"
          fontSize={'14px'}
          fontWeight={'600'}
        >
          No Data Available
        </Text>
      );
    }
  };

  return (
    <>
      {/* <ApplicantDashboard /> */}
      <Box>
        <Heading
          p="4"
          fontSize={'24px'}
          fontWeight={'600'}
          borderBottomWidth="1px"
        >
          Dashboard Details
        </Heading>
        {isLoading ? (
          <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Box mt="4">{renderCardData()}</Box>
        )}
      </Box>
      <Flex alignItems={'center'} flexDirection={'column'} columnGap={5}>
        <Box w={'50%'}>
          <Select
            w="100%"
            onChange={handleBrokerageSelect}
            value={selectedBrokerageId}
            name="userData.brokerageId"
            borderRadius="5px"
            bg={'#114684'}
            color={'white'}
            textAlign={'center'}
          >
            <option
              value={''}
              style={{ color: 'black' }}
              label="Select Applicant"
            />
            {applicantOptions.map((user?: any, index?: number) => (
              <option
                style={{ color: 'black' }}
                key={index}
                value={user?.applicantId ?? ''}
                label={user?.companyName ?? ''}
              />
            ))}
          </Select>
        </Box>
        <Box mt="20px" w="50%" mb={10}>
          <Button
            isLoading={
              (brokerageDashboard.status === 'loading' &&
                brokerageDashboard.type === 'GET_APPLICANT_PROFILE_STATUS') ||
              isOngoingDataLoading
            }
            w={'100%'}
            type="submit"
            bg="#114684"
            color="white"
            _hover={{ bg: '#114684' }}
            onClick={() =>
              applicationId
                ? navigate(`/application/${applicationId}`)
                : setQboModal(true)
            }
            isDisabled={isloadingApp || !selectedBrokerageId}
            position={'relative'}
          >
            {!brokerageDashboard.selectedApplicantData?.isProfileCompleted ? (
              <Link
                style={{
                  position: 'absolute',
                  inset: 0,
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                to={`/broker/applicant-profile?applicantId=${selectedBrokerageId}`}
                onClick={(e) => e.stopPropagation()}
              >
                Complete Applicant Profile
              </Link>
            ) : applicationId ? (
              'Update Application'
            ) : (
              'Create Application'
            )}
          </Button>
        </Box>
      </Flex>
      <AutoPopulateModal
        isOpen={qboModal}
        onClose={() => setQboModal(false)}
        onConfirm={() => {
          setQboModal(false);
          setAutoPopulateModalOpen(true);
        }}
        onDecline={declinedAutoPopulate}
        confirmButtonText="Yes"
        declinedButtonText="No"
        title={`Do you use QuickBooks (QBO) online?`}
      />
      <AutoPopulateModal
        isOpen={autoPopulateModalOpen}
        onClose={() => setAutoPopulateModalOpen(false)}
        onConfirm={confirmAutoPopulate}
        onDecline={declinedAutoPopulate}
        confirmButtonText="Approve"
        declinedButtonText="Decline"
        title={`Can we have your permission to access your accounting data to
        generate financial reports for insurance purposes?`}
        subTitle={`Why do we need this`}
        tooltip={`Securely pulling financial information from your accounting system significantly expedites the bonding and insurance underwriting process`}
      />
    </>
  );
}

export const AutoPopulateModal = ({
  isOpen,
  onClose,
  onConfirm,
  onDecline,
  confirmButtonText,
  declinedButtonText,
  title,
  subTitle,
  tooltip,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onDecline: () => void;
  confirmButtonText: string;
  declinedButtonText: string;
  title: string;
  subTitle?: string | undefined;
  tooltip?: string | undefined;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={12}>
          <VStack textAlign={'center'}>
            <CiCircleInfo color="#3182ce" fontSize={64} />
            <Text as={'h2'} fontSize={18}>
              {title}
            </Text>
            <HStack gap={1} alignItems={'center'}>
              {subTitle && <Text fontSize={12}>{subTitle}</Text>}
              {tooltip && (
                <Tooltip
                  fontSize="md"
                  w={'max-content'}
                  label={<Text>{tooltip}</Text>}
                  placement={'right'}
                >
                  <Box>
                    <HiMiniInformationCircle size={14} cursor={'pointer'} />
                  </Box>
                </Tooltip>
              )}
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent={'center'} gap={4}>
          <AppSolidButton minW={'100px'} onClick={onConfirm}>
            {confirmButtonText}
          </AppSolidButton>
          <Button colorScheme="red" onClick={onDecline} minW={'100px'}>
            {declinedButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BrokerDashboard;
