import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApplicationFilter } from 'src/types/enums';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Documents/slice';
import initialState from './state';

export const getApplications = createAsyncThunk(
  'appliactionsStore/getApplications',
  async (
    { applicationFilter }: { applicationFilter: ApplicationFilter },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(
        `application/listApplications?applicationFilter=${applicationFilter}`,
        'GET'
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getApplicationById = createAsyncThunk(
  'appliactionsStore/getApplicationById',
  async ({ applicationId }: { applicationId: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(`/application/${applicationId}`, 'GET');

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getGlobalHistory = createAsyncThunk(
  'appliactionsStore/getGlobalHistory',
  async ({ applicationId }: { applicationId: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        `history/list?applicationId=${applicationId}&modelName=Application`,
        'GET'
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const appliactionsStore = createSlice({
  name: 'appliactionsStore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get application by status
    builder
      .addCase(getApplications.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICATIONS_BY_STATUS';
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_APPLICATIONS_BY_STATUS';
        state.applications = action.payload.data;
      })
      .addCase(getApplications.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICATIONS_BY_STATUS';
        state.error = isRejectedActionWithPayload(action);
      })
      // GET application by id
      .addCase(getApplicationById.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICATION_BY_ID';
      })
      .addCase(getApplicationById.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_APPLICATION_BY_ID';
        state.currentApplication = action.payload.data;
      })
      .addCase(getApplicationById.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICATION_BY_ID';
        state.error = isRejectedActionWithPayload(action);
      })

      // GET application by id
      .addCase(getGlobalHistory.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICATION_HISTORY';
      })
      .addCase(getGlobalHistory.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_APPLICATION_HISTORY';
        state.applicationHistory = action.payload.data;
      })
      .addCase(getGlobalHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICATION_HISTORY';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const appliactionsSlice = appliactionsStore.reducer;

export default appliactionsSlice;
