export type APIStatus = 'succeed' | 'failed' | 'loading';

export interface DocumentsData {
  status: APIStatus | null;
  error: any;
  msg?: string;
  data?: any;
}

const initialState: DocumentsData = {
  error: null,
  status: null,
};

export default initialState;
