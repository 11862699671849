import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApplicationById } from 'src/Redux/Applications/slice';
import { useAppToast } from 'src/hooks';
import { CheckQboStatus } from '../../../Redux/Applicant/Qbo/Qbo';
import {
  Creditbureau,
  creditBureauUpdate,
  RescindFromApplicant,
} from '../../../Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { getApplicantHistory } from '../../../Redux/Broker/Dashboard/History';
import { RootState, useAppSelector } from '../../../Redux/Store';
import { CreditBureauSchema } from '../../../ValidationSchema/Pages/Index';
import {
  deepObjectComparison,
  formatDate,
  getValue,
  handleMinMaxNumberInput,
  trimStringValues,
} from '../../../utils/helpers';
import { IsSubmited } from './Stepper';

const LegalForm = forwardRef<any, any>(
  (
    {
      isSubmited,
      setIsSubmited,
      formData,
      accountingSystem,
    }: {
      formData: TLegalAPIData;
      isSubmited: IsSubmited;
      setIsSubmited: (pros: IsSubmited) => void;
      accountingSystem: TAccountingSystem;
    },
    ref
  ) => {
    console.log('LegalForm-formData', formData);

    const Toast = useToast();
    const dispatch = useDispatch();
    const [showTextarea, setShowTextarea] = useState({
      hasHistory: false,
      hasGuarantees: false,
      hasRelatedCompanies: false,
      hasChanges: false,
      hasLiens: false,
      hasLiensFiledByCompany: false,
      hasOutstandingJudgments: false,
      hasDisputes: false,
      hasDisputesWithSuppliers: false,
    });

    const location = useLocation();
    const { showBox, showHistory, applicationStatus, applicantId } =
      location.state || {};
    const [historyIndex, setHistoryIndex] = useState(0);

    const [isReturnToSender, setIsReturnToSender] = useState(false);
    const [loader, setLoader] = useState<{ btnLoader: boolean }>({
      btnLoader: false,
    });
    const [rescindFromApplicant, setRescindFromApplicant] =
      useState<boolean>(false);
    const navigate = useNavigate();
    const paramsData = useParams<{ id: string }>();
    const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
    ///user
    // const userData = useSelector(
    //   (state: RootState) => state.createApplicationFormSlice.user
    // );
    const HistoryData: any = useSelector(
      (state: RootState) => state.history.user
    );
    const createApplicationForm = useAppSelector(
      (state: RootState) => state.createApplicationFormSlice
    );
    const toast = useAppToast();
    const currentHistoryData = HistoryData?.data[historyIndex]?.details;

    // let userGetidData = userData && userData.data;
    // let brokerid = userGetidData && userGetidData.brokerId;
    // let applicationId = userGetidData && userGetidData.applicationId;

    //ongoing
    // const data: any = useSelector(
    //   (state: RootState) => state.brokerageDashboard.user
    // );

    // let creditBureauFormData = data && data?.data?.creditBureau;
    const AccoutingFormData = accountingSystem;
    // let ongoingapplicationId =
    //   data && data?.data?.accountingSystem?.applicationId;
    // let ongoingbrokerId = data && data?.data?.accountingSystem?.brokerId;

    //applicant fetch
    // const applicantFetch = useSelector(
    //   (state: RootState) => state.notification.user
    // );
    // const applicantFetchData =
    //   applicantFetch && applicantFetch?.data?.applications;
    // const applicantObject = applicantFetchData?.find(
    //   (applicant: any) => applicant.applicationId === ongoingID
    // );

    const qboState = useAppSelector((state: RootState) => state.qbo);

    const userType = localStorage.getItem('userType');
    const isApplicantUser = userType === 'applicant';
    const applicationId = formData?.applicationId;
    const brokerId = formData?.brokerId;

    // useEffect(() => {
    //   if (ongoingID) {
    //     fetchOngoingApplicant();
    //   }
    //   if (!showHistory) {
    //     fetchDashboardData();
    //   }
    // }, [dispatch, ongoingID]);

    // const fetchOngoingApplicant = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let id = !showHistory && ongoingID ? ongoingID : 0;
    //     id = showHistory && applicantId ? applicantId : id;
    //     let actionResult = await dispatch(OngoingApplicant({ id: id }) as any);
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    // const fetchDashboardData = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let actionResult = await dispatch(
    //       fetchDashboardDetails({ applicationFilter: '' }) as any
    //     );
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    useEffect(() => {
      const fetchData = async () => {
        if (!!localStorage.getItem('Token')) {
          if (ongoingID && showHistory) {
            let actionResult = await dispatch(
              getApplicantHistory({
                applicationId: ongoingID,
                modelName: 'Credit Bureau',
              }) as any
            );
            if (actionResult?.payload?.status === 401) {
              navigate('/login');
            }
          }
        } else {
          navigate('/login');
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
      const { status, type, error, user } = createApplicationForm;

      switch (status) {
        case 'loading': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: true });
          }
          break;
        }
        case 'succeed': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: false });
            setRescindFromApplicant(false);
            toast({
              status: 'success',
              title: user?.data,
            });
            fetchOngoingApplication();
          }
          break;
        }
        case 'failed': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: false });
            setRescindFromApplicant(false);
            toast({
              status: 'error',
              title: error,
            });
          }
          break;
        }

        default:
          break;
      }
    }, [createApplicationForm.status]);

    useEffect(() => {
      if (formData) {
        const historyRelatedCompanies =
          currentHistoryData?.relatedCompanies?.companies;
        const companies = getValue(
          showHistory,
          currentHistoryData?.relatedCompanies?.companies,
          formData?.relatedCompanies?.companies
        );
        const relatedCompanies = companies
          ? companies?.map((company: any, i: number) => ({
              name: getValue(
                showHistory,
                historyRelatedCompanies?.[i]?.name,
                company?.name
              ),
              natureOfOperations: getValue(
                showHistory,
                historyRelatedCompanies?.[i]?.natureOfOperations,
                company?.natureOfOperations
              ),
              ownershipStructure: getValue(
                showHistory,
                historyRelatedCompanies?.[i]?.ownershipStructure,
                company?.ownershipStructure
              ),
            }))
          : initialValues?.relatedCompanies?.companies;
        const apiValues = {
          ...initialValues,
          applicationId: formData?.applicationId,
          brokerId: formData?.brokerId,
          applicantId: formData?.applicantId,
          receivershipOrBankruptcyHistory: {
            hasHistory: getValue(
              showHistory,
              currentHistoryData?.receivershipOrBankruptcyHistory?.hasHistory,
              formData?.receivershipOrBankruptcyHistory?.hasHistory
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.receivershipOrBankruptcyHistory?.details,
              formData?.receivershipOrBankruptcyHistory?.details
            ),
          },
          guaranteesForOtherParties: {
            hasGuarantees: getValue(
              showHistory,
              currentHistoryData?.guaranteesForOtherParties?.hasGuarantees,
              formData?.guaranteesForOtherParties?.hasGuarantees
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.guaranteesForOtherParties?.details,
              formData?.guaranteesForOtherParties?.details
            ),
          },
          relatedCompanies: {
            hasRelatedCompanies: getValue(
              showHistory,
              currentHistoryData?.relatedCompanies?.hasRelatedCompanies,
              formData?.relatedCompanies?.hasRelatedCompanies
            ),
            companies: relatedCompanies,
          },
          changesInManagement: {
            hasChanges: getValue(
              showHistory,
              currentHistoryData?.changesInManagement?.hasChanges,
              formData?.changesInManagement?.hasChanges
            ),
            explanation: getValue(
              showHistory,
              currentHistoryData?.changesInManagement?.explanation,
              formData?.changesInManagement?.explanation
            ),
          },
          liensFiledAgainstCompany: {
            hasLiens: getValue(
              showHistory,
              currentHistoryData?.liensFiledAgainstCompany?.hasLiens,
              formData?.liensFiledAgainstCompany?.hasLiens
            ),
            // amount: getValue(
            //   showHistory,
            //   currentHistoryData?.liensFiledAgainstCompany?.amount,
            //   formData?.liensFiledAgainstCompany?.amount
            // ),
            details: getValue(
              showHistory,
              currentHistoryData?.liensFiledAgainstCompany?.details,
              formData?.liensFiledAgainstCompany?.details
            ),
          },
          stateFederalTax: getValue(
            showHistory,
            currentHistoryData?.stateFederalTax,
            formData?.stateFederalTax
          ),
          paymentPlan: getValue(
            showHistory,
            currentHistoryData?.paymentPlan,
            formData?.paymentPlan
          ),
          // taxingEntityDoc: getValue(
          //   showHistory,
          //   currentHistoryData?.taxingEntityDoc,
          //   formData?.taxingEntityDoc
          // ),
          outstandingJudgments: {
            hasOutstandingJudgments: getValue(
              showHistory,
              currentHistoryData?.outstandingJudgments?.hasOutstandingJudgments,
              formData?.outstandingJudgments?.hasOutstandingJudgments
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.outstandingJudgments?.details,
              formData?.outstandingJudgments?.details
            ),
          },
          bondClaims: getValue(
            showHistory,
            currentHistoryData?.bondClaims,
            formData?.bondClaims
          ),
          claimsPaid: getValue(
            showHistory,
            currentHistoryData?.claimsPaid,
            formData?.claimsPaid
          ),
          suretyMade: getValue(
            showHistory,
            currentHistoryData?.suretyMade,
            formData?.suretyMade
          ),
          whyNotMade: getValue(
            showHistory,
            currentHistoryData?.whyNotMade,
            formData?.whyNotMade
          ),
          disputesWithOthers: {
            hasDisputes: getValue(
              showHistory,
              currentHistoryData?.disputesWithOthers?.hasDisputes,
              formData?.disputesWithOthers?.hasDisputes
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.disputesWithOthers?.details,
              formData?.disputesWithOthers?.details
            ),
          },
          liensFiledByCompany: {
            hasLiens: getValue(
              showHistory,
              currentHistoryData?.liensFiledByCompany?.hasLiens,
              formData?.liensFiledByCompany?.hasLiens
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.liensFiledByCompany?.details,
              formData?.liensFiledByCompany?.details
            ),
          },
          disputesWithSuppliers: {
            hasDisputes: getValue(
              showHistory,
              currentHistoryData?.disputesWithSuppliers?.hasDisputes,
              formData?.disputesWithSuppliers?.hasDisputes
            ),
            fundingMeans: getValue(
              showHistory,
              currentHistoryData?.disputesWithSuppliers?.fundingMeans,
              formData?.disputesWithSuppliers?.fundingMeans
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.disputesWithSuppliers?.details,
              formData?.disputesWithSuppliers?.details
            ),
          },
          buySellAgreement: getValue(
            showHistory,
            currentHistoryData?.buySellAgreement,
            formData?.buySellAgreement
          ),
          agreementDetails: getValue(
            showHistory,
            currentHistoryData?.agreementDetails,
            formData?.agreementDetails
          ),
          returnToSender: userType === 'broker' ? false : null,
          applicationName: getValue(
            showHistory,
            currentHistoryData?.applicationName,
            formData?.applicationName || ''
          ),
        };
        setShowTextarea({
          hasHistory: getValue(
            showHistory,
            currentHistoryData?.receivershipOrBankruptcyHistory?.hasHistory,
            formData?.receivershipOrBankruptcyHistory?.hasHistory
          ),
          hasGuarantees: getValue(
            showHistory,
            currentHistoryData?.guaranteesForOtherParties?.hasGuarantees,
            formData?.guaranteesForOtherParties?.hasGuarantees
          ),
          hasRelatedCompanies: getValue(
            showHistory,
            currentHistoryData?.relatedCompanies?.hasRelatedCompanies,
            formData?.relatedCompanies?.hasRelatedCompanies
          ),
          hasChanges: getValue(
            showHistory,
            currentHistoryData?.changesInManagement?.hasChanges,
            formData?.changesInManagement?.hasChanges
          ),
          hasLiens: getValue(
            showHistory,
            currentHistoryData?.liensFiledAgainstCompany?.hasLiens,
            formData?.liensFiledAgainstCompany?.hasLiens
          ),
          hasLiensFiledByCompany: getValue(
            showHistory,
            currentHistoryData?.liensFiledByCompany?.hasLiens,
            formData?.liensFiledByCompany?.hasLiens
          ),
          hasOutstandingJudgments: getValue(
            showHistory,
            currentHistoryData?.outstandingJudgments?.hasOutstandingJudgments,
            formData?.outstandingJudgments?.hasOutstandingJudgments
          ),
          hasDisputes: getValue(
            showHistory,
            currentHistoryData?.disputesWithOthers?.hasDisputes,
            formData?.disputesWithOthers?.hasDisputes
          ),
          hasDisputesWithSuppliers: getValue(
            showHistory,
            currentHistoryData?.disputesWithSuppliers?.hasDisputes,
            formData?.disputesWithSuppliers?.hasDisputes
          ),
        });
        setInitialValues(apiValues);
      }
    }, [formData, currentHistoryData, AccoutingFormData]);

    const [initialValues, setInitialValues] = useState<TLegal>({
      applicationId: applicationId,

      brokerId: brokerId,
      applicantId: formData?.applicantId,
      receivershipOrBankruptcyHistory: {
        hasHistory: null,
        details: null,
      },
      guaranteesForOtherParties: {
        hasGuarantees: null,
        details: null,
      },

      relatedCompanies: {
        hasRelatedCompanies: null,
        companies: [
          {
            name: null,
            natureOfOperations: null,
            ownershipStructure: null,
          },
        ],
      },
      changesInManagement: {
        hasChanges: null,
        explanation: null,
      },
      liensFiledAgainstCompany: {
        hasLiens: null,
        // amount: null,
        details: null,
      },
      stateFederalTax: null,
      paymentPlan: null,
      // taxingEntityDoc: null,
      outstandingJudgments: {
        hasOutstandingJudgments: null,
        details: null,
      },
      bondClaims: null,
      claimsPaid: null,
      suretyMade: null,
      whyNotMade: null,
      disputesWithOthers: {
        hasDisputes: null,
        details: null,
      },
      liensFiledByCompany: {
        hasLiens: null,
        details: null,
      },
      disputesWithSuppliers: {
        hasDisputes: null,
        details: null,
        fundingMeans: null,
      },
      buySellAgreement: null,
      agreementDetails: null,
      returnToSender: userType === 'broker' ? false : null,
      applicationName: '',
    });

    const OnHandelReturnToSender = () => {
      setIsReturnToSender(true);
    };

    const OnHandleReturnToBroker = () => {
      setRescindFromApplicant(true);
      dispatch(
        RescindFromApplicant({
          applicationId: formData?.applicationId,
          formName: 'creditBureau',
        }) as any
      );
    };

    // const fetchId = bureauId ? bureauId : ongoingbureauId;
    // const fetchId = true;

    const formik = useFormik({
      enableReinitialize: true,
      initialValues,

      validationSchema: CreditBureauSchema(),

      onSubmit: async (values: TLegal) => {
        setIsSubmited({ ...isSubmited, 'Credit Bureau': true });
        let res = '';
        try {
          if (!isReturnToSender) {
            let editedValues: any = {};
            const updatedData: Partial<TLegal> = deepObjectComparison(
              values,
              formData
            );
            // const keys: (keyof TLegal)[] = Object.keys(
            //   values
            // ) as (keyof TLegal)[];

            // for (const key of keys) {
            //   if (
            //     !deepObjectComparison(
            //       (values as any)[key],
            //       creditBureauFormData[key]
            //     )
            //   ) {
            //     (editedValues as any)[key] = values[key];
            //   }
            // }

            editedValues = {
              ...values,
              updatedData: updatedData,
              // returnToSender: isApplicantUser,
              // returnToSender: false,
              applicationId: formData?.applicationId,
              applicantId: formData?.applicantId,
              brokerId: formData?.brokerId,
              applicationName: formData?.applicationName,
            };
            const trimedValues = trimStringValues(editedValues);
            console.log('applicationId', applicationId);

            const actionResult = await dispatch(
              creditBureauUpdate({
                updatedData: trimedValues,
                applicationId: applicationId,
              }) as any
            );
            const response = actionResult.payload;
            res = response.msg;
            const resStatus = response.status;

            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          } else if (isReturnToSender) {
            let editedValues: any = {};
            const updatedData: Partial<TLegal> = deepObjectComparison(
              values,
              formData
            );
            // const keys: (keyof TLegal)[] = Object.keys(
            //   values
            // ) as (keyof TLegal)[];

            // for (const key of keys) {
            //   if (
            //     !deepObjectComparison(
            //       (values as any)[key],
            //       creditBureauFormData[key]
            //     )
            //   ) {
            //     (editedValues as any)[key] = values[key];
            //   }
            // }

            editedValues = {
              ...values,
              updatedData: updatedData,
              // returnToSender: isApplicantUser,
              returnToSender: userType === 'applicant' ? false : true,
              applicationId: formData?.applicationId,
              applicantId: formData?.applicantId,
              brokerId: formData?.brokerId,
              applicationName: formData?.applicationName,
            };
            const trimedValues = trimStringValues(editedValues);
            setLoader({ ...loader, btnLoader: true });
            const actionResult = await dispatch(
              creditBureauUpdate({
                updatedData: trimedValues,
                applicationId: applicationId,
              }) as any
            );
            if (userType === 'broker') {
              const response = actionResult.payload;
              res = response.msg;
              const resStatus = response.status;

              if (resStatus === 200) {
                Toast({
                  title: response.msg,
                  status: 'success',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
                fetchOngoingApplication();
              } else {
                Toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              }
            } else {
              navigate('/');
            }
            setIsReturnToSender(false);
            setLoader({ ...loader, btnLoader: false });
          } else {
            const actionResult = await dispatch(Creditbureau(values) as any);
            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;

            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              if (resStatus !== 401) {
                Toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              } else {
                navigate('/login');
              }
            }
          }
        } catch (error: any) {
          if (res) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      },
    });

    const { values, setFieldValue, errors, touched } = formik;

    useImperativeHandle(ref, () => ({
      formik: formik,
    }));

    function capitalizeFirstLetter(str: any) {
      const dotIndex = str.indexOf('.');
      const modifiedStr = dotIndex !== -1 ? str.slice(0, dotIndex) : str;
      return (
        modifiedStr.charAt(0).toUpperCase() +
        modifiedStr
          .slice(1)
          .replace(/[A-Z]/g, (match: any) => ' ' + match.toLowerCase()) +
        (dotIndex !== -1 ? ' can not be null.' : '.')
      );
    }

    // Check QBO Status
    useEffect(() => {
      const appId = applicationId;
      if (appId && !qboState.escReason) {
        fetchCheckQboStatus(appId);
      }
    }, [applicationId]);

    const fetchCheckQboStatus = async (appId: number) => {
      if (!!localStorage.getItem('Token')) {
        let actionResult = await dispatch(CheckQboStatus(appId) as any);
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };

    const fetchOngoingApplication = async () => {
      dispatch(
        getApplicationById({
          applicationId,
        }) as any
      );
    };

    const handleAddBox = (index: number) => {
      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        let relatedCompanies = newValues?.relatedCompanies || {};
        const companies = newValues?.relatedCompanies?.companies || [];

        companies?.push({
          name: null,
          natureOfOperations: null,
          ownershipStructure: null,
        });

        relatedCompanies = { ...relatedCompanies, companies: companies };

        return {
          ...newValues,
          relatedCompanies,
        };
      });
    };

    const handleRemoveBox = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        let relatedCompanies = newValues?.relatedCompanies || {};
        const companies = newValues?.relatedCompanies?.companies || [];

        if (companies?.length > 1) {
          const removeIndex = index;
          companies?.splice(removeIndex, 1);
          relatedCompanies = { ...relatedCompanies, companies: companies };

          return {
            ...newValues,
            relatedCompanies,
          };
        }

        return newValues;
      });
    };

    return (
      <Box
        display="flex"
        gap={4}
        w="min(88rem,95%)"
        margin={'-236px auto 0'}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Container
          maxW={'container.lg'}
          boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
          h="auto"
          p="20px"
          borderRadius="20px"
          gap={5}
          bg={'white'}
          // overflow="auto" maxHeight="calc(100vh - 150px)" sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box display={'flex'} flexDirection="column" gap={5}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box width={'100%'}>
                  <Text
                    textAlign="start"
                    fontSize={12}
                    color="red"
                    fontWeight="600"
                    pr={5}
                  >
                    {/* Pending Applicant Consent ! */}
                  </Text>
                </Box>
                <Box width={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="center"
                    fontSize={20}
                  >
                    Legal{' '}
                    {/* {applicantHeaderName && applicantHeaderName.length > 0
                        ? `: ${applicantHeaderName} `
                        : null} */}
                  </Text>
                </Box>
                <Box width={'100%'}></Box>
              </Box>
              {/* <Box>
                  {!qboState.loading &&
                    applicationStatus !== 'Drafts' &&
                    !qboState?.escAccess && (
                      <AlertBox
                        variant={qboState?.escAccess ? 'success' : 'error'}
                        message={qboState?.escReason}
                      />
                    )}
                </Box> */}
              <Box display={'flex'} flexDirection={'column'} gap={3}>
                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={3}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Has the company, any prior company or any of the
                          owners or their spouses or a company owned by any of
                          them ever been placed in receivership or bankruptcy?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.receivershipOrBankruptcyHistory
                              .hasHistory === true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'receivershipOrBankruptcyHistory.hasHistory',
                              e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasHistory: e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.receivershipOrBankruptcyHistory
                              .hasHistory === false
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'receivershipOrBankruptcyHistory.hasHistory',
                              !e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasHistory: !e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.receivershipOrBankruptcyHistory?.hasHistory &&
                      errors.receivershipOrBankruptcyHistory?.hasHistory && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.receivershipOrBankruptcyHistory?.hasHistory
                          )}
                        </div>
                      )}
                  </Box>
                  {showTextarea.hasHistory && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            Provide details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>
                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={
                              values.receivershipOrBankruptcyHistory.details ??
                              ''
                            }
                            name="receivershipOrBankruptcyHistory.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              currentHistoryData?.updatedData
                                ?.receivershipOrBankruptcyHistory &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.receivershipOrBankruptcyHistory &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              currentHistoryData?.updatedData
                                ?.receivershipOrBankruptcyHistory &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.receivershipOrBankruptcyHistory &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.receivershipOrBankruptcyHistory?.details &&
                          errors.receivershipOrBankruptcyHistory?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.receivershipOrBankruptcyHistory?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Has the company, its owners or any of the related
                          companies provided any guarantees for the indebtedness
                          of any other party, outside Corporation or
                          individuals? <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.guaranteesForOtherParties.hasGuarantees ===
                            true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'guaranteesForOtherParties.hasGuarantees',
                              e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasGuarantees: e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.guaranteesForOtherParties.hasGuarantees ===
                            false
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'guaranteesForOtherParties.hasGuarantees',
                              !e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasGuarantees: !e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.guaranteesForOtherParties?.hasGuarantees &&
                      errors.guaranteesForOtherParties?.hasGuarantees && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {errors.guaranteesForOtherParties?.hasGuarantees}
                        </div>
                      )}
                  </Box>
                  {showTextarea.hasGuarantees && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Provide details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={
                              values.guaranteesForOtherParties?.details ?? ''
                            }
                            name="guaranteesForOtherParties.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              currentHistoryData?.updatedData
                                ?.guaranteesForOtherParties &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.guaranteesForOtherParties &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              currentHistoryData?.updatedData
                                ?.guaranteesForOtherParties &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.guaranteesForOtherParties &&
                              'white'
                            }
                          />
                        </Box>

                        {touched.guaranteesForOtherParties?.details &&
                          errors.guaranteesForOtherParties?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.guaranteesForOtherParties?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Does the company have one or more related companies?
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.relatedCompanies.hasRelatedCompanies === true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'relatedCompanies.hasRelatedCompanies',
                              e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasRelatedCompanies: e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.relatedCompanies.hasRelatedCompanies ===
                            false
                          }
                          onChange={(e) => {
                            setFieldValue('relatedCompanies', {
                              hasRelatedCompanies: !e.target.checked,
                              companies: [
                                {
                                  name: null,
                                  natureOfOperations: null,
                                  ownershipStructure: null,
                                },
                              ],
                            });
                            setShowTextarea({
                              ...showTextarea,
                              hasRelatedCompanies: !e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {showTextarea.hasRelatedCompanies && (
                      <Box>
                        {formik?.values?.relatedCompanies?.companies?.map(
                          (item: any, i: number) => (
                            <>
                              <Box
                                display={{ base: 'block', md: 'flex' }}
                                flex={{ base: 1, md: 1 }}
                                gap={3}
                              >
                                <Box
                                  maxWidth={'50%'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  justifyContent={'space-between'}
                                >
                                  <Box>
                                    <Text
                                      fontWeight="600"
                                      color="#114684"
                                      textAlign="start"
                                      fontSize={13}
                                    >
                                      <li>Name</li>
                                    </Text>
                                  </Box>
                                  <Box>
                                    <Box
                                      display="flex"
                                      mb={{ base: '20px', md: '5px' }}
                                      mt={2}
                                    >
                                      <Input
                                        fontSize={'13px'}
                                        w="100%"
                                        type="text"
                                        placeholder="Name"
                                        borderRadius="5px"
                                        focusBorderColor="#7f7f7f"
                                        borderColor={'#7f7f7f'}
                                        _hover={{ borderColor: '#7f7f7f' }}
                                        onChange={formik.handleChange}
                                        value={
                                          values.relatedCompanies?.companies?.[
                                            i
                                          ]?.name ?? ''
                                        }
                                        name={`relatedCompanies.companies[${i}].name`}
                                        readOnly={showBox}
                                        bgColor={
                                          showHistory &&
                                          currentHistoryData?.updatedData &&
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[
                                            i
                                          ] &&
                                          'name' in
                                            currentHistoryData?.updatedData
                                              ?.relatedCompanies?.companies?.[
                                              i
                                            ] &&
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[i]
                                            ?.name !== null &&
                                          '#114684'
                                        }
                                        color={
                                          showHistory &&
                                          currentHistoryData?.updatedData &&
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[
                                            i
                                          ] &&
                                          'name' in
                                            currentHistoryData?.updatedData
                                              ?.relatedCompanies?.companies?.[
                                              i
                                            ] &&
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[i]
                                            ?.name !== null &&
                                          'white'
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  maxWidth={'50%'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  justifyContent={'space-between'}
                                >
                                  <Box>
                                    <Text
                                      fontWeight="600"
                                      color="#114684"
                                      textAlign="start"
                                      fontSize={13}
                                    >
                                      <li>
                                        Nature of operations of related company
                                      </li>
                                    </Text>
                                  </Box>

                                  <Box
                                    display="flex"
                                    mb={{ base: '20px', md: '5px' }}
                                    mt={2}
                                  >
                                    <Input
                                      fontSize={'13px'}
                                      w="100%"
                                      type="text"
                                      placeholder="Nature of operations of related companies"
                                      borderRadius="5px"
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{ borderColor: '#7f7f7f' }}
                                      onChange={formik.handleChange}
                                      value={
                                        values.relatedCompanies?.companies?.[i]
                                          ?.natureOfOperations ?? ''
                                      }
                                      name={`relatedCompanies.companies[${i}].natureOfOperations`}
                                      readOnly={showBox}
                                      bgColor={
                                        showHistory &&
                                        currentHistoryData?.updatedData &&
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                        'natureOfOperations' in
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[
                                            i
                                          ] &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        currentHistoryData?.updatedData &&
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                        'natureOfOperations' in
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[
                                            i
                                          ] &&
                                        'white'
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  maxWidth={'50%'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  justifyContent={'space-between'}
                                >
                                  <Box>
                                    <Text
                                      fontWeight="600"
                                      color="#114684"
                                      textAlign="start"
                                      fontSize={13}
                                    >
                                      <li>
                                        What(%) is the ownership structure of
                                        this related company
                                      </li>
                                    </Text>
                                  </Box>

                                  <Box
                                    display="flex"
                                    mb={{ base: '20px', md: '5px' }}
                                    mt={2}
                                  >
                                    <Input
                                      fontSize={'13px'}
                                      w="100%"
                                      type="text"
                                      placeholder="What % is the ownership structure of this related company "
                                      borderRadius="5px"
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{ borderColor: '#7f7f7f' }}
                                      onChange={(e: any) => {
                                        formik.handleChange(e);
                                        const value = handleMinMaxNumberInput({
                                          inputValue: e.target.value,
                                          max: 100,
                                        });
                                        formik.setFieldValue(
                                          `relatedCompanies.companies[${i}].ownershipStructure`,
                                          value
                                        );
                                      }}
                                      value={
                                        values.relatedCompanies?.companies?.[i]
                                          ?.ownershipStructure ?? ''
                                      }
                                      name={`relatedCompanies.companies[${i}].ownershipStructure`}
                                      readOnly={showBox}
                                      bgColor={
                                        showHistory &&
                                        currentHistoryData?.updatedData &&
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                        'ownershipStructure' in
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[
                                            i
                                          ] &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        currentHistoryData?.updatedData &&
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                        'ownershipStructure' in
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[
                                            i
                                          ] &&
                                        'white'
                                      }
                                    />
                                  </Box>
                                </Box>
                                {i === 0 ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop="18px"
                                    onClick={() => handleAddBox(i)}
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCirclePlus />
                                  </Box>
                                ) : (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop="18px"
                                    onClick={() => handleRemoveBox(i)}
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCircleMinus />
                                  </Box>
                                )}
                              </Box>
                            </>
                          )
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Have there been any changes in the control, ownership,
                          or management of the company in the past 3 years or
                          are any such changes impending?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Box>
                        <Stack spacing={5} direction="row">
                          <Checkbox
                            colorScheme="green"
                            isChecked={
                              values.changesInManagement?.hasChanges === true
                            }
                            onChange={(e) => {
                              setFieldValue(
                                'changesInManagement.hasChanges',
                                e.target.checked
                              );
                              setShowTextarea({
                                ...showTextarea,
                                hasChanges: e.target.checked,
                              });
                            }}
                            readOnly={showBox}
                          >
                            Yes
                          </Checkbox>
                          <Checkbox
                            colorScheme="red"
                            isChecked={
                              values.changesInManagement?.hasChanges === false
                            }
                            onChange={(e) => {
                              setFieldValue(
                                'changesInManagement.hasChanges',
                                !e.target.checked
                              );
                              setShowTextarea({
                                ...showTextarea,
                                hasChanges: !e.target.checked,
                              });
                            }}
                            readOnly={showBox}
                          >
                            No
                          </Checkbox>
                        </Stack>
                      </Box>
                    </Box>
                    {touched.changesInManagement?.hasChanges &&
                      errors.changesInManagement?.hasChanges && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {errors.changesInManagement?.hasChanges}
                        </div>
                      )}
                  </Box>
                  {showTextarea.hasChanges && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            Provide details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={
                              values.changesInManagement?.explanation ?? ''
                            }
                            name="changesInManagement.explanation"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.changesInManagement &&
                              'explanation' in
                                currentHistoryData?.updatedData
                                  ?.changesInManagement &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.changesInManagement &&
                              'explanation' in
                                currentHistoryData?.updatedData
                                  ?.changesInManagement &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.changesInManagement?.explanation &&
                          errors.changesInManagement?.explanation && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.changesInManagement?.explanation}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there any liens for labour or material filed
                          against you by anyone?{' '}
                          <span style={{ color: 'red' }}>
                            {' '}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.liensFiledAgainstCompany?.hasLiens === true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'liensFiledAgainstCompany.hasLiens',
                              e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasLiens: e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.liensFiledAgainstCompany?.hasLiens === false
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'liensFiledAgainstCompany.hasLiens',
                              !e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasLiens: !e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.liensFiledAgainstCompany?.hasLiens &&
                      errors.liensFiledAgainstCompany?.hasLiens && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.liensFiledAgainstCompany?.hasLiens
                          )}
                        </div>
                      )}
                  </Box>

                  {values.liensFiledAgainstCompany?.hasLiens && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      gap={5}
                    >
                      {/* <Box flex={{ base: "1", md: "1" }}>
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>
                              {" "}
                              If yes, what is the $ amount?{" "}
                              <span style={{ color: "red" }}>*</span>
                            </li>
                          </Text>

                          <Box
                            display="flex"
                            mb={{ base: "20px", md: "5px" }}
                            mt={2}
                          >
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="number"
                              onWheel={(e) =>
                                (e.target as HTMLInputElement).blur()
                              }
                              placeholder="If yes, what is the $ amount?"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={"#7f7f7f"}
                              _hover={{ borderColor: "#7f7f7f" }}
                              onChange={formik.handleChange}
                              value={
                                values.liensFiledAgainstCompany
                                  ?.amount ?? ""
                              }
                              name="liensFiledAgainstCompany.amount"
                              readOnly={showBox}
                              onKeyDown={preventAlphabetsTyping}
                              bgColor={
                                showHistory &&
                                currentHistoryData?.updatedData?.liensFiledAgainstCompany &&
                                "amount" in
                                currentHistoryData?.updatedData?.liensFiledAgainstCompany &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData?.updatedData?.liensFiledAgainstCompany &&
                                "amount" in
                                currentHistoryData?.updatedData?.liensFiledAgainstCompany &&
                                "white"
                              }
                            />
                          </Box>
                          {touched.liensFiledAgainstCompany?.amount &&
                            errors.liensFiledAgainstCompany?.amount && (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "13px",
                                  textAlign: "start",
                                  fontWeight: "500",
                                }}
                              >
                                {errors.liensFiledAgainstCompany?.amount}
                              </div>
                            )}
                        </Box> */}
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Provide details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={
                              values.liensFiledAgainstCompany?.details ?? ''
                            }
                            name="liensFiledAgainstCompany.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.liensFiledAgainstCompany &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.liensFiledAgainstCompany &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.liensFiledAgainstCompany &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.liensFiledAgainstCompany &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.liensFiledAgainstCompany?.details &&
                          errors.liensFiledAgainstCompany?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.liensFiledAgainstCompany?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                {AccoutingFormData?.country === 'United States' && (
                  <Box
                    bgColor={'#f5f5f5'}
                    borderRadius={'8px'}
                    p={3}
                    display={'flex'}
                    flexDir={'column'}
                    gap={5}
                  >
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there any state or federal tax liens that are
                          open?{' '}
                          {/* <span style={{ color: "red" }}>
                          {" "}
                          <span style={{ color: "red" }}>*</span>
                        </span> */}
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={values.stateFederalTax === true}
                          onChange={(e) => {
                            setFieldValue('stateFederalTax', e.target.checked);
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasLiens: e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={values.stateFederalTax === false}
                          onChange={(e) => {
                            setFieldValue('stateFederalTax', !e.target.checked);
                            setFieldValue('paymentPlan', null);
                            // setFieldValue('taxingEntityDoc', null);
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasLiens: !e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {values.stateFederalTax && (
                      <Box
                        display={'flex'}
                        flexDir={{ base: 'column', lg: 'row' }}
                        justifyContent={'space-between'}
                        gap={{ base: 2, lg: 5 }}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            Is there a payment plan in place? Provide a copy of
                            the agreement or documentation from the taxing
                            entity.{' '}
                            {/* <span style={{ color: "red" }}>
                          {" "}
                          <span style={{ color: "red" }}>*</span>
                        </span> */}
                          </li>
                        </Text>
                        <Stack
                          spacing={5}
                          direction="row"
                          alignItems={'flex-start'}
                        >
                          {values.paymentPlan && (
                            <Tooltip
                              fontSize="md"
                              label="Upload the document under Documents tab under Other"
                              placement={'auto'}
                            >
                              <Box display={{ base: 'none', lg: 'block' }}>
                                <HiMiniInformationCircle
                                  size={22}
                                  cursor={'pointer'}
                                  color="#114684"
                                />
                              </Box>
                            </Tooltip>
                          )}
                          <Checkbox
                            colorScheme="green"
                            isChecked={values.paymentPlan === true}
                            onChange={(e) => {
                              setFieldValue('paymentPlan', e.target.checked);
                              // setShowTextarea({
                              //   ...showTextarea,
                              //   hasLiens: e.target.checked,
                              // });
                            }}
                            readOnly={showBox}
                          >
                            Yes
                          </Checkbox>
                          <Checkbox
                            colorScheme="red"
                            isChecked={values.paymentPlan === false}
                            onChange={(e) => {
                              setFieldValue('paymentPlan', !e.target.checked);
                              // setFieldValue('taxingEntityDoc', null);
                              // setShowTextarea({
                              //   ...showTextarea,
                              //   hasLiens: !e.target.checked,
                              // });
                            }}
                            readOnly={showBox}
                          >
                            No
                          </Checkbox>
                          {values.paymentPlan && (
                            <Tooltip
                              fontSize="md"
                              label="Upload the document under Documents tab under Other"
                              placement={'auto'}
                            >
                              <Box display={{ base: 'block', lg: 'none' }}>
                                <HiMiniInformationCircle
                                  size={22}
                                  cursor={'pointer'}
                                  color="#114684"
                                />
                              </Box>
                            </Tooltip>
                          )}
                        </Stack>
                      </Box>
                    )}

                    {/* {values.stateFederalTax && values?.paymentPlan && (
                        <Box
                          display={'flex'}
                          flexDir={{ base: 'column', lg: 'row' }}
                          justifyContent={'space-between'}
                          gap={{ base: 2, lg: 5 }}
                        >
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>Upload document from taxing entity. </li>
                          </Text>
                          <Stack spacing={5} direction="row">
                            <Checkbox
                              colorScheme="green"
                              isChecked={values.taxingEntityDoc === true}
                              onChange={(e) => {
                                setFieldValue(
                                  'taxingEntityDoc',
                                  e.target.checked
                                );
                              }}
                              readOnly={showBox}
                            >
                              Yes
                            </Checkbox>
                            <Checkbox
                              colorScheme="red"
                              isChecked={values.taxingEntityDoc === false}
                              onChange={(e) => {
                                setFieldValue(
                                  'taxingEntityDoc',
                                  !e.target.checked
                                );
                              }}
                              readOnly={showBox}
                            >
                              No
                            </Checkbox>
                          </Stack>
                        </Box>
                      )} */}
                  </Box>
                )}

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there any judgments, suits or claims outstanding
                          against your company its officers or any company of
                          either? <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.outstandingJudgments
                              .hasOutstandingJudgments === true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'outstandingJudgments.hasOutstandingJudgments',
                              e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasOutstandingJudgments: e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.outstandingJudgments
                              .hasOutstandingJudgments === false
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'outstandingJudgments.hasOutstandingJudgments',
                              !e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasOutstandingJudgments: !e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.outstandingJudgments?.hasOutstandingJudgments &&
                      errors.outstandingJudgments?.hasOutstandingJudgments && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.outstandingJudgments?.hasOutstandingJudgments
                          )}
                        </div>
                      )}
                  </Box>
                  {values.outstandingJudgments?.hasOutstandingJudgments && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      gap={5}
                    >
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Provide details{' '}
                            <span style={{ color: 'red' }}>
                              {' '}
                              <span style={{ color: 'red' }}>*</span>
                            </span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={values.outstandingJudgments?.details ?? ''}
                            name="outstandingJudgments.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.outstandingJudgments &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.outstandingJudgments &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.outstandingJudgments &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.outstandingJudgments &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.outstandingJudgments?.details &&
                          errors.outstandingJudgments?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.outstandingJudgments?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box
                    display={'flex'}
                    flexDir={{ base: 'column', lg: 'row' }}
                    justifyContent={'space-between'}
                    gap={{ base: 2, lg: 5 }}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        Are there any claims made against a surety bond?
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={values.bondClaims === true}
                        onChange={(e) => {
                          setFieldValue('bondClaims', e.target.checked);
                          // setShowTextarea({
                          //   ...showTextarea,
                          //   hasOutstandingJudgments: e.target.checked,
                          // });
                        }}
                        readOnly={showBox}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={values.bondClaims === false}
                        onChange={(e) => {
                          setFieldValue('bondClaims', !e.target.checked);
                          setFieldValue('claimsPaid', null);
                          setFieldValue('suretyMade', null);
                          // setShowTextarea({
                          //   ...showTextarea,
                          //   hasOutstandingJudgments: !e.target.checked,
                          // });
                        }}
                        readOnly={showBox}
                      >
                        No
                      </Checkbox>
                    </Stack>
                  </Box>

                  {values?.bondClaims && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Were the claims paid?
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={values.claimsPaid === true}
                          onChange={(e) => {
                            setFieldValue('claimsPaid', e.target.checked);
                            setFieldValue('suretyMade', null);
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasOutstandingJudgments: e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={values.claimsPaid === false}
                          onChange={(e) => {
                            setFieldValue('claimsPaid', !e.target.checked);
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasOutstandingJudgments: !e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                  )}

                  {values?.bondClaims && values?.claimsPaid && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Was the surety made whole?
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={values.suretyMade === true}
                          onChange={(e) => {
                            setFieldValue('suretyMade', e.target.checked);
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasOutstandingJudgments: e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={values.suretyMade === false}
                          onChange={(e) => {
                            setFieldValue('suretyMade', !e.target.checked);
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasOutstandingJudgments: !e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                  )}

                  {values?.bondClaims &&
                    values?.claimsPaid &&
                    values.suretyMade === false && (
                      <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                        <Box flex={{ base: '1', md: '1' }}>
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>
                              {' '}
                              Provide details{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </li>
                          </Text>

                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Textarea
                              fontSize={'13px'}
                              w="100%"
                              placeholder="Provide details"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={formik.handleChange}
                              value={values?.whyNotMade ?? ''}
                              name="whyNotMade"
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData?.updatedData &&
                                'whyNotMade' in
                                  currentHistoryData?.updatedData &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData?.updatedData &&
                                'whyNotMade' in
                                  currentHistoryData?.updatedData &&
                                'white'
                              }
                            />
                          </Box>
                          {touched.whyNotMade && errors.whyNotMade && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.whyNotMade}
                            </div>
                          )}
                        </Box>
                      </Box>
                    )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there significant disputes for any work which you
                          did or failed to do?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.disputesWithOthers?.hasDisputes === true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'disputesWithOthers.hasDisputes',
                              e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasDisputes: e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.disputesWithOthers?.hasDisputes === false
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'disputesWithOthers.hasDisputes',
                              !e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasDisputes: !e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.disputesWithOthers?.hasDisputes &&
                      errors.disputesWithOthers?.hasDisputes && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.disputesWithOthers?.hasDisputes
                          )}
                        </div>
                      )}
                  </Box>
                  {showTextarea.hasDisputes && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Provide details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={values.disputesWithOthers?.details ?? ''}
                            name="disputesWithOthers.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.disputesWithOthers &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.disputesWithOthers &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.disputesWithOthers &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.disputesWithOthers &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.disputesWithOthers?.details &&
                          errors.disputesWithOthers?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.disputesWithOthers?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there any liens for labour or material filed by
                          you against a third party?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.liensFiledByCompany?.hasLiens === true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'liensFiledByCompany.hasLiens',
                              e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasLiensFiledByCompany: e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.liensFiledByCompany?.hasLiens === false
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'liensFiledByCompany.hasLiens',
                              !e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasLiensFiledByCompany: !e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                    {touched.liensFiledByCompany?.hasLiens &&
                      errors.liensFiledByCompany?.hasLiens && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.liensFiledByCompany?.hasLiens
                          )}
                        </div>
                      )}
                  </Box>
                  {showTextarea.hasLiensFiledByCompany && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Provide details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={values.liensFiledByCompany?.details ?? ''}
                            name="liensFiledByCompany.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.liensFiledByCompany &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.liensFiledByCompany &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.liensFiledByCompany &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.liensFiledByCompany &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.liensFiledByCompany?.details &&
                          errors.liensFiledByCompany?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.liensFiledByCompany?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          {' '}
                          Is the company presently engaged in any dispute with
                          suppliers or sub trades relative to their work or
                          materials?<span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.disputesWithSuppliers?.hasDisputes === true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'disputesWithSuppliers.hasDisputes',
                              e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasDisputesWithSuppliers: e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.disputesWithSuppliers?.hasDisputes === false
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'disputesWithSuppliers.hasDisputes',
                              !e.target.checked
                            );
                            setShowTextarea({
                              ...showTextarea,
                              hasDisputesWithSuppliers: !e.target.checked,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                    {touched.disputesWithSuppliers?.hasDisputes &&
                      errors.disputesWithSuppliers?.hasDisputes && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.disputesWithSuppliers?.hasDisputes
                          )}
                        </div>
                      )}
                  </Box>
                  {showTextarea.hasDisputesWithSuppliers && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      gap={5}
                    >
                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDir={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            Provide details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>
                        <Box>
                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Textarea
                              fontSize={'13px'}
                              w="100%"
                              placeholder="Provide details"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={formik.handleChange}
                              value={
                                values.disputesWithSuppliers?.details ?? ''
                              }
                              name="disputesWithSuppliers.details"
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData?.updatedData
                                  ?.disputesWithSuppliers &&
                                'details' in
                                  currentHistoryData?.updatedData
                                    ?.disputesWithSuppliers &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData?.updatedData
                                  ?.disputesWithSuppliers &&
                                'details' in
                                  currentHistoryData?.updatedData
                                    ?.disputesWithSuppliers &&
                                'white'
                              }
                            />
                          </Box>
                          {touched.disputesWithSuppliers?.details &&
                            errors.disputesWithSuppliers?.details && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.disputesWithSuppliers?.details}
                              </div>
                            )}
                        </Box>
                      </Box>
                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDir={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                          display={'flex'}
                          gap={'3px'}
                          alignItems={'flex-start'}
                          flexWrap={'nowrap'}
                        >
                          <li>
                            By what means is it funded? Provide a copy of the
                            agreement.{' '}
                            <span style={{ color: 'red', marginRight: '2px' }}>
                              *
                            </span>
                          </li>
                          <Tooltip
                            fontSize="md"
                            label="Upload the agreement under Documents tab under Other"
                            placement={'auto'}
                            // shouldWrapChildren={true}
                          >
                            <Box marginBottom={'-7px'}>
                              <HiMiniInformationCircle
                                size={22}
                                cursor={'pointer'}
                                color="#114684"
                              />
                            </Box>
                          </Tooltip>
                        </Text>
                        <Box>
                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Textarea
                              fontSize={'13px'}
                              w="100%"
                              placeholder="By what means is it funded? Provide a copy of the agreement."
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={formik.handleChange}
                              value={
                                values.disputesWithSuppliers.fundingMeans ?? ''
                              }
                              name="disputesWithSuppliers.fundingMeans"
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData?.updatedData
                                  ?.disputesWithSuppliers &&
                                'fundingMeans' in
                                  currentHistoryData?.updatedData
                                    ?.disputesWithSuppliers &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData?.updatedData
                                  ?.disputesWithSuppliers &&
                                'fundingMeans' in
                                  currentHistoryData?.updatedData
                                    ?.disputesWithSuppliers &&
                                'white'
                              }
                            />
                          </Box>
                          {touched.disputesWithSuppliers?.fundingMeans &&
                            errors.disputesWithSuppliers?.fundingMeans && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.disputesWithSuppliers?.fundingMeans}
                              </div>
                            )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Is there a formal Buy/Sell agreement.{' '}
                          {/* <span style={{ color: 'red' }}>*</span> */}
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={values.buySellAgreement === true}
                          onChange={(e) =>
                            setFieldValue('buySellAgreement', e.target.checked)
                          }
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={values.buySellAgreement === false}
                          onChange={(e) => {
                            setFieldValue(
                              'buySellAgreement',
                              !e.target.checked
                            );
                            setFieldValue('agreementDetails', null);
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                    {touched.buySellAgreement && errors.buySellAgreement && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {capitalizeFirstLetter(errors.buySellAgreement)}
                      </div>
                    )}
                  </Box>
                  {values.buySellAgreement && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Provide further details regarding the agreement{' '}
                            {/* <span style={{ color: 'red' }}>*</span> */}
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide further details regarding the agreement"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={values.agreementDetails ?? ''}
                            name="agreementDetails"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              'agreementDetails' in
                                currentHistoryData?.updatedData &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              'agreementDetails' in
                                currentHistoryData?.updatedData &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.agreementDetails &&
                          errors.agreementDetails && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.agreementDetails}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              marginTop={'10px'}
              gap={2}
            >
              {!showBox && (
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  onClick={
                    userType === 'broker' && formData?.assignToApplicant
                      ? () => OnHandleReturnToBroker()
                      : () => OnHandelReturnToSender()
                  }
                  type={
                    userType === 'broker' && formData?.assignToApplicant
                      ? 'button'
                      : 'submit'
                  }
                  isLoading={loader?.btnLoader}
                  // isDisabled={
                  //   userType === 'broker' && formData?.assignToApplicant
                  //     ? true
                  //     : false
                  // }
                >
                  {isApplicantUser
                    ? 'Return to Broker'
                    : userType === 'broker' && formData?.assignToApplicant
                      ? 'Edit - Rescind Assignment from Applicant'
                      : 'Assign to applicant'}
                </Button>
              )}
              {!showBox && (
                <Box>
                  <Button
                    bg={'#114684'}
                    color={'white'}
                    _hover={{ bg: '#114684', color: 'white' }}
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        </Container>
        {showHistory && (
          <>
            <Box
              height="calc(100vh - 150px)"
              w={{
                base: '100%',
                lg: '360px',
              }}
              sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
              bg={'white'}
              display={'flex'}
              flexDir={'column'}
              gap={'10px'}
              borderRadius={'12px'}
              boxShadow="0 0 14px -6px rgba(0,0,0,0.3)"
              p={4}
            >
              <Box style={{ fontSize: '25px', fontWeight: 'bold' }}>
                History
              </Box>
              <Box
                display={'flex'}
                flexDir={'column'}
                gap={'10px'}
                overflow={'auto'}
              >
                {HistoryData?.data?.map((item: any, index: number) => {
                  const formattedDate = formatDate(item?.createdAt);
                  return (
                    <Box
                      key={item?.historyId}
                      bgColor={historyIndex == index ? '#1146844a' : '#f5f5f5'}
                      p={3}
                      borderRadius={'12px'}
                      cursor={'pointer'}
                      mr={2}
                      border={
                        historyIndex == index
                          ? '1px solid #114684'
                          : '1px solid gray'
                      }
                      onClick={() => setHistoryIndex(index)}
                    >
                      <Box fontWeight={500} mt={2} display={'flex'}>
                        <Box marginRight={1}>Date :</Box>
                        <Text> {formattedDate.date}</Text>
                        <Text
                          mx={2}
                          borderLeft="1px solid #114684"
                          height="auto"
                          boxShadow="none"
                        ></Text>
                        <Text>{formattedDate.time}</Text>
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Status : {item?.applicationStatus}
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Initiated By : {item?.initiatedBy}
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Updated By : {item?.broker?.firstName}
                        &nbsp;
                        {item?.broker?.lastName}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  }
);

export default LegalForm;
