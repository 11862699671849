export const financialOption = [
  { label: 'Financial Statement', value: 'Financial Statement' },
  {
    label: 'Corporate Organization & Shareholders',
    value: 'Corporate Organization & Shareholders',
  },
  { label: 'Banking', value: 'Banking' },
  { label: 'Workers Compensation', value: 'Workers Compensation' },
  { label: 'Key Personnel Resume', value: 'Key Personnel Resume' },
  { label: 'Other', value: 'Other' },
];

export const financialYearOption = [
  {
    label: 'Most Recent Interim Financial Statement',
    value: 'Most Recent Interim Financial Statement',
  },
  {
    label: 'Most Recent Fiscal Year End',
    value: 'Most Recent Fiscal Year End',
  },
  {
    label: '1-Year Previous Fiscal Year End',
    value: '1-Year Previous Fiscal Year End',
  },
  {
    label: '2-Year Previous Fiscal Year End',
    value: '2-Year Previous Fiscal Year End',
  },
];
export const financialStatementOption = [
  { label: 'Account Receivable Aging', value: 'Account Receivable Aging' },
  { label: 'Account Payable Aging', value: 'Account Payable Aging' },
  { label: 'Balance Sheet', value: 'Balance Sheet' },
  { label: 'Profit and Loss', value: 'Profit and Loss' },
  { label: 'Cashflow', value: 'Cashflow' },
];
export const corporateOrgShareholdersOption = [
  {
    label: 'Corporate Organizational Chart',
    value: 'Corporate Organizational Chart',
  },
  {
    label: 'Shareholder Agreement (specify name of Shareholder)',
    value: 'Shareholder Agreement',
  },
  {
    label: 'Personal Net Worth Statement (specify name of Shareholder)',
    value: 'Personal Net Worth Statement',
  },
];
export const bankingOption = [
  {
    label: 'Bank Terms and Agreement (specify what bank and for what LOC)',
    value: 'Bank Terms and Agreement',
  },
  {
    label: 'Bank Conditions Letter (specify what bank and for what LOC)',
    value: 'Bank Conditions Letter',
  },
];
export const workersCompensationOption = [
  {
    label: 'Workers Compensation Review of Performance',
    value: 'Workers Compensation Review of Performance',
  },
];

export const otherOptionOptions = [
  {
    label:
      'If the company is presently engaged in any dispute with suppliers or sub trades relative to their work or materials, upload the funding agreement',
    value: 'Copy Of Agreement',
  },
];
export const OtherOptionWithtaxingEntity = [
  ...otherOptionOptions,
  {
    label: `If there are claims paid against a surety bond, upload the taxing entity document`,
    value: 'Taxing Entity Document',
  },
];
